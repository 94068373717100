import { debounce, isEqual } from 'lodash';
import { useRef } from 'react';

export const useUpdateProfile = (initValue, list, updateCb) => {
  const openRef = useRef(false);
  const valueRef = useRef(initValue);

  const update = debounce((name, roles) => {
    updateCb(name, roles);
  }, 2000);

  const onChange = (e, data) => {
    const roles = list.filter((el) => data.value.includes(el.id));
    if (roles) {
      valueRef.current = roles;
      if (!openRef.current) {
        const { name } = data;
        update(name, roles);
      }
    }
  };

  const onClose = (e, data) => {
    if (
      !isEqual(
        data.defaultValue,
        valueRef.current.map((v) => v.id)
      ) &&
      openRef.current
    ) {
      updateCb(data.name, valueRef.current);
    }
    openRef.current = false;
  };

  const onOpen = () => {
    openRef.current = true;
  };

  return {
    onOpen,
    onChange,
    onClose,
  };
};
