import { autorun, makeAutoObservable, runInAction } from 'mobx';
import * as Api from 'services';

import { ROUTES } from 'utils';
import { platformFiltersMap } from 'stores/platform.store';
import { POST_FIELDS } from 'contants/common';

export default class PostStore {
  loading = false;
  posts = [];
  total = 0;
  activePost = {};
  postTags = [];
  popularTags = [];
  activePostComments = [];
  commentToReply = null;
  activePostCommentsReplies = [];
  lastSelectedPopularTagsId = [];
  filterParams = {
    [platformFiltersMap.count]: 10,
  };
  modalFilterParams = this.filterParams;

  currentPage = 0;
  isFirstPage = true;
  isLastPage = false;
  totalPages = 0;

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  loadTagData = async () => {
    await this.rootStore?.optionsStore?.loadOptions();
    runInAction(() => {
      this.postTags = this.rootStore?.optionsStore?.tags;
    });
  };
  loadPopularTags = async () => {
    const result = await Api.loadPopularTagData();
    if (result) {
      runInAction(() => {
        this.popularTags = result;
      });
    }
  };
  selectPopularTags = (id) => {
    const filtredTags =
      this.filterParams.tags && this.filterParams.tags.length
        ? [...this.filterParams.tags, id]
        : [id];
    runInAction(() => {
      this.filterParams.tags = filtredTags;
      this.lastSelectedPopularTagsId = id;
    });
  };

  addPost = async (data) => {
    const result = await Api.addPost(data);
    if (result && result.id) {
      this.loadPosts();
    }
  };
  loadPosts = async (platformParams = this.filterParams) => {
    this.loading = true;
    platformParams = { ...platformParams, ...this.filterParams };
    let response = null;
    if (this.rootStore.userStore.isAuth) {
      response = await Api.loadPosts(platformParams);
    } else {
      response = await Api.loadPublicPosts( {
        [platformFiltersMap.count]: 5,
      });
    }
    if (response && response.content) {
      runInAction(() => {
        this.posts = response.content;
        this.total = response.totalElements;
        this.totalPages = response.totalPages;
        this.currentPage = response.number;
        this.isLastPage = response.last;
        this.isFirstPage = response.first;
      });
    }
    this.loading = false;
    return response;
  };
  loadNextPage = async () => {
    if (!this.isLastPage) {
      const platformParams = { ...this.rootStore.platformStore.platformFilter,
        [platformFiltersMap.page]: this.currentPage + 1 };
      this.loadPosts(platformParams);
    }
  };
  loadPreviousPage = async () => {
    if (!this.isFirstPage) {
      const platformParams = { ...this.rootStore.platformStore.platformFilter,
        [platformFiltersMap.page]: this.currentPage - 1 };
      this.loadPosts(platformParams);
    }
  };

  loadOnePost = async (id) => {
    const result = await Api.loadOnePost(id);
    if (result) {
      runInAction(() => {
        this.activePost = result;
      });
      return true;
    }
    return false;
  };
  voteForPost = async (post) => {
    const vote = await Api.voteForPost(post.id);
    const result = await Api.loadOnePost(post.id);
    const { voted } = result;
    if (vote) {
      runInAction(() => {
        this.posts = this.posts.map((item) =>
          item.id === post.id ? { ...item, voteCount: vote.voteCount, voted: voted } : item
        );
      });
    }
  };
  voteForActivePost = async (postId) => {
    const vote = await Api.voteForPost(postId);
    const result = await Api.loadOnePost(postId);
    const { voted } = result;
    if (vote) {
      runInAction(() => {
        this.activePost.voteCount = vote.voteCount;
        this.activePost.voted = voted;
      });
    }
  };
  deletePost = async (id) => {
    const res = await Api.deletePost(id);
    if (res) {
      runInAction(() => {
        if (this.activePost.id === id) {
          this.rootStore.router.history.push(ROUTES.NEST);
          this.activePost = {};
        }
        this.posts = this.posts.filter((item) => item.id !== id);
      });
    }
  };

  editPost = async (data, id) => {
    data = { ...data, [POST_FIELDS.TAGS]: data.tags.map((t) => ({ id: t })) };
    const result = await Api.editPost(data, id);
    if (result.status === 204) {
      await this.loadOnePost(id);
      this.posts = this.posts.map((item) => (item.id === id ? this.activePost : item));
    }

    if (result && result.data?.payload) {
      const post = result.data.payload;
      if (post.id === this.activePost?.id) {
        this.activePost = post;
      } else {
        this.posts = this.posts.map((item) => (item.id === post.id ? post : item));
      }
    }
  };

  /*Comments*/

  nullifySelectedPopularTags = () => {
    this.lastSelectedPopularTagsId = null;
  };

  voteForComment = async (data) => {
    const vote = await Api.voteForComment(data);
    const loadedComment = await Api.loadOneCommentToPost(data.postId, data.commentId);
    runInAction(() => {
      if (data.parentId) {
        this.activePostCommentsReplies.forEach((item) =>
          item.replies.forEach((reply) => {
            if (data.commentId === reply.id) {
              reply.voteCount = vote.voteCount;
              reply.voted = loadedComment.voted;
            }
          })
        );
      } else {
        this.activePostComments.forEach((comment) => {
          if (data.commentId === comment.id) {
            comment.voteCount = vote.voteCount;
            comment.voted = loadedComment.voted;
          }
        });
      }
    });
  };

  loadCommentsToPost = async (id, sortBy, sortOrder, page = 0) => {
    const result = await Api.loadCommentsToPost(id, sortBy, sortOrder, page);
    if (result && result.content) {
      runInAction(() => {
        if (!page) this.activePostComments = result.content;
        else this.activePostComments = [...this.activePostComments, ...result.content];
        if (result.content[0]?.notificationId) {
          this.rootStore.notificationStore.readOneNotification(result.content[0].notificationId);
          this.rootStore.notificationStore.loadNotifications(0, true);
        }
      });
    }
  };

  loadRepliesToPostComments = async (postId, commentId) => {
    const result = await Api.loadRepliesToPostComments(postId, commentId);
    if (result && result.content) {
      runInAction(() => {
        const loadedBeforeReplies = this.activePostCommentsReplies.find(
          (c) => c.parentId === commentId
        );
        if (loadedBeforeReplies) {
          this.activePostCommentsReplies.forEach((post) => {
            if (post.parentId === commentId) {
              post.replies = result.content;
            }
          });
        } else {
          this.activePostCommentsReplies = [
            ...this.activePostCommentsReplies,
            {parentId: commentId, replies: result.content},
          ];
        }
      });
    }
  };

  setRepliesToPostComments = async () => {
    this.activePostCommentsReplies = [];
  };

  addComment = async (data) => {
    const comment = await Api.addComment(data);
    if (comment && comment.id) {
      const result = await Api.loadOneCommentToPost(data.parentId, comment.id);
      if (data.type === 'ANSWER') {
        runInAction(() => {
          this.activePostComments.unshift(result);
        });
      }
      if (data.type === 'COMMENT') {
        runInAction(() => {
          this.activePostCommentsReplies.forEach((reply) => {
            if (reply.parentId === result.parentId) {
              reply.replies = [result, ...reply.replies];
            }
          });
        });
      }
    }
  };

  selectCommentToReply = (comment) => {
    this.commentToReply = comment;
  };
  deleteComment = async (commentId, postId, parentId) => {
    const res = await Api.deleteComment(commentId, postId);
    if (res) {
      if (parentId) {
        const deletedItem = this.activePostCommentsReplies.find(
          (comment) => comment.parentId === parentId
        );
        if (deletedItem) {
          deletedItem.replies = deletedItem.replies.filter((reply) => reply.id !== commentId);
        }
      } else {
        runInAction(() => {
          this.activePostComments = this.activePostComments.filter(
            (comment) => comment.id !== commentId
          );
          this.activePostCommentsReplies = this.activePostCommentsReplies.filter(
            (comment) => comment.parentId !== parentId
          );
        });
      }
    }
  };

  editComment = async (commentId, postId, data, parentId) => {
    const result = await Api.editComment(commentId, postId, data);

    if (result.status === 204) {
      if (parentId) {
        const item = this.activePostCommentsReplies.find(
          (comment) => comment.parentId === parentId
        );
        if (item) {
          const reply = item.replies.find((r) => r.id === commentId);
          if (reply) {
            reply.body = data.body;
          }
        }
      } else {
        const item = this.activePostComments.find((comment) => comment.id === commentId);
        if (item) {
          item.body = data.body;
        }
      }
    }
  };
}
