import React, { cloneElement, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ModalTransition from 'components/common/ModalTransition/ModalTransition';
import CloseButton from 'components/UI/CloseButton/CloseButton';
import PropTypes from 'prop-types';
import { useUIStore } from 'stores';

const ModalWrapper = (
  { children, Trigger, unregisterCb, size, className, registerCb, withoutAuth },
  ref
) => {
  const [show, setShow] = useState(false);
  const { notAuthorizedHandler } = useUIStore();

  const openHandler = () => {
    if (registerCb) registerCb();
    setShow(true);
  };

  const closeHandler = () => {
    if (unregisterCb) unregisterCb();
    setShow(false);
  };

  const TriggerButton = cloneElement(
    Trigger,
    { onClick: withoutAuth ? openHandler : () => notAuthorizedHandler(openHandler) },
    Trigger.props.children
  );

  return (
    <>
      {TriggerButton}
      <ModalTransition
        onClose={closeHandler}
        onOpen={openHandler}
        show={show}
        size={size}
        className={className}
      >
        <CloseButton ref={ref} onClose={closeHandler} />
        {children}
      </ModalTransition>
    </>
  );
};

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  Trigger: PropTypes.node.isRequired,
  unregisterCb: PropTypes.oneOfType(PropTypes.bool, PropTypes.func),
  size: PropTypes.string,
  className: PropTypes.string,
  registerCb: PropTypes.oneOfType(PropTypes.bool, PropTypes.func),
  withoutAuth: PropTypes.bool,
};

ModalWrapper.defaultProps = {
  size: '',
  className: '',
  unregisterCb: false,
  registerCb: false,
  withoutAuth: false,
};

export default observer(ModalWrapper, { forwardRef: true });
