import React from 'react';
import { observer } from 'mobx-react-lite';
import './CookieSingleItem.scss';
import { Checkbox } from 'semantic-ui-react';

const CookieSingleItem = ({ title, isAlwaysActive, description, onChange, value }) => (
  <div className="cookie-single-item">
    <div className="title-box">
      <div className="title">{title}</div>
      {isAlwaysActive ? (
        <div className="title-small">Always active</div>
      ) : (
        <Checkbox onChange={onChange} value={value} checked={value} />
      )}
    </div>
    <p className="description">{description}</p>
  </div>
);

export default observer(CookieSingleItem);
