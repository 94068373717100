import axios from 'axios';
import { errorNotification, errorPromiseCather, toggleLoader } from 'utils';
import apiAxios from 'axiosConfig';
import { rootStore } from 'stores';

export const registerUser = async (data) => {
  try {
    const res = await axios
      .post('nest/api/public/experts/sign-up', {
        industryId: null,
        expertiseAreaId: null,
        expertRoleIds: [],
        specialityIds: [],
        digitalHealthSkillIds: [],
        ...data,
      })
      .catch((error) => errorPromiseCather(error));
    return res;
  } catch (error) {
    console.error(error);
    if (error.message && error.message.includes('already in use')) {
      return { error: true, message: error.message };
    }
    errorNotification(error.message);
    return null;
  }
};

export const loginUser = async (data) => {
  try {
    return await toggleLoader(() =>
      axios
        .post('nest/login', data)
        .then((res) => {
          const token = res.headers.authorization;
          return { expert: res.data.payload, token };
        })
        .catch((error) => {
          errorPromiseCather(error);
        })
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
  return false;
};

export const checkAuth = async () => {
  try {
    return await apiAxios
      .get('nest/token')
      .then((res) => res.data.payload)
      .catch((error) => {
        errorPromiseCather(error);
      });
  } catch (e) {
    // errorNotification(e.message);
    console.error(e);
  }
};

export const getExpertProfile = async () => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`nest/api/login/expert`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
  return false;
};

export const updateRefreshToken = async () => {
  try {
    return await axios
      .patch('nest/token')
      .then((res) => res.data.payload)
      .catch((error) => errorPromiseCather(error));
  } catch (e) {
    // errorNotification(e.message);
    console.error(e);
  }
};

export const confirmEmail = async (token) => {
  try {
    const res = await axios.get(`nest/api/public/experts/confirm?${token}`);
    return res;
  } catch (error) {
    // errorNotification(error.message);
    if (error.message === 'Request failed with status code 400') {
      return { status: 400 };
    }
    return null;
  }
};

export const logout = async () => {
  try {
    return await apiAxios.get('nest/logout').catch((error) => errorPromiseCather(error));
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const deleteProfile = async (expertId, profileId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .delete(`nest/api/experts/${expertId}/profiles/${profileId}`)
        .then((res) => res.status)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const getFullExpertProfile = async (id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`nest/api/experts/${id}/profiles`)
        .then((res) => res.data.payload)
        // .then((res) => res.data)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
  return false;
};

export const getNotificationSettings = async (profileId) => {
  if (profileId) {
    try {
      return await toggleLoader(() =>
        apiAxios
          .get(`nest/api/experts/${profileId}/settings/notifications`)
          .then((res) => res.data.payload)
          .catch((error) => errorPromiseCather(error))
      );
    } catch (error) {
      errorNotification(error.message);
      console.error(error);
    }
    return false;
  }
};

export const updateNotificationSettings = async (profileId, settingId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .patch(`nest/api/experts/${profileId}/settings/notifications/${settingId}`)
        .then((res) => res.data)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
  return false;
};

export const updateProfile = async (expertId, profileId, profile) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .put(`nest/api/experts/${expertId}/profiles/${profileId}`, profile, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((res) => res.status)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
  return false;
};

export const uploadAvatar = async (file, expertId, profileId) => {
  try {
    const url =
      expertId && profileId
        ? `nest/api/experts/${expertId}/profiles/${profileId}/avatar`
        : `nest/api/public/experts/avatar`;
    const http = expertId && profileId ? apiAxios : axios;
    return await toggleLoader(() =>
      http
        .post(url, file, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((res) => res.data)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const changePassword = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/experts/password/change`, data)
        .then((res) => res.status)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification('Something went wrong. Check your password.');
    console.error(error);
  }
};

export const resetPassword = async (email) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/public/experts/password/reset/check-email?email=${email}`)
        .then((res) => res.status)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message || 'Something went wrong.');
    console.error(error);
  }
};

export const checkCode = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/public/experts/password/reset/check-code`, data)
        .then((res) => res.status)
    );
  } catch (error) {
    console.log(error);
    if (error?.response?.status > 400 && error?.response?.status < 500) {
      errorNotification('Verification code has expired');
    } else {
      errorNotification('Something went wrong.');
    }
    console.error(error);
  }
};

export const changeForgottenPassword = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/public/experts/password/reset/change-password`, data)
        .then((res) => res.status)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification('Something went wrong.');
    console.error(error);
  }
};

export const removeAvatar = async (expertId, profileId, profileImageUrl) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .delete(`nest/api/experts/${expertId}/profiles/${profileId}/avatar/${profileImageUrl}`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
