import { errorNotification, errorPromiseCather, toggleLoader } from 'utils';
import apiAxios from 'axiosConfig';
import qs from 'qs';
export const loadMyActivities = async (expertId,params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/experts/${expertId}/activities`, {
          params,
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'comma', encode:  true }),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const loadSavedActivities = async (expertId,params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        // .get(postsURL, {
        .get(`/nest/api/experts/${expertId}/activities/saved`, {
          params,
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'comma', encode:  true }),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
}
export const saveToMyActivities = async (activityId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .patch(`nest/api/activities/${activityId}/saved/toggle`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const loadOneActivity = async (id,withView) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/activities/${id}?countViews=${withView}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification('Activity loading error');
    console.error(error);
  }
};

export const loadActivities = async (params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        // .get(postsURL, {
        .get('/nest/api/activities', {
          params,
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'comma', encode:  true }),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const loadOnePublicActivity = async (id,withView) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/public/activities/${id}?countViews=${withView}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification('Activity loading error');
    console.error(error);
  }
};
export const loadPublicActivities = async (params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get('nest/api/public/activities',{
          params,
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'comma', encode:  true }),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const addActivity = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post('/nest/api/activities', data)
        .then((res) => res.data.payload)
        .catch((error) => {
          console.log(error);
          errorPromiseCather(error);
        })
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const uploadActivityImage = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post('/nest/api/activities/media/upload', data, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((res) => res.data.payload)
        .catch((error) => {
          errorPromiseCather(error);
        })
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const updateActivity = async (data, id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .put(`/nest/api/activities/${id}`, data)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const deleteActivity = async (id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .delete(`nest/api/activities/${id}`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
