import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { getAvatarUrl, getFullName, ROUTES, stringToColor } from 'utils';
import { Button, Dropdown } from 'semantic-ui-react';
import {useUIStore, useUserStore} from 'stores';
import Avatar from 'components/UI/Avatar';
import Messages from "./Messages";
import Notification from './Notification';
const NavbarRight = () => {
  const history = useHistory();
  const {isPhone } = useUIStore();
  const { logout, isAuth, user } = useUserStore();
  const { firstName, profileImageUrl, lastName, avatarIcon } = user;
  const fullName = getFullName(firstName, lastName);
  const {setSideBarOpened} = useUIStore();
  const logoutHandler = async () => {
    await logout();
    const logoutMessage = "You're logged out from your account";
    history.push({ pathname: ROUTES.SIGN_IN, logoutMessage });
  };

  const viewProfileHandler = async () => {
    history.push(`${ROUTES.PROFILE}/${user.expertId}`);
  };
    const viewOurNetwork = async () => {
        history.push(`${ROUTES.FIND_EXPERT}`);
    };
  const viewSettingsHandler = async () => {
    history.push(ROUTES.SETTINGS);
  };
  const viewMyProjectsHandler = async () => {
    history.push(ROUTES.MY_PROJECTS);
  };
  const viewHistoryPaymentsHandler = async () => {
    history.push(ROUTES.PAYMENTS_HISTORY)
  }
  const dropdownOptions = [
    {
      key: 'View profile',
      text: 'View profile',
      value: 'viewProfile',
      icon: 'icon-user',
      onClick: viewProfileHandler,
    },

    {
      key: 'My Listings',
      text: 'My Listings',
      value: 'viewMyProjects',
      icon: 'icon-folder',
      onClick: viewMyProjectsHandler,
    },
    {
      key: 'Payments',
      text: 'Payments',
      value: 'viewHistoryPayments',
      icon: 'icon-payment',
      onClick: viewHistoryPaymentsHandler,
    },
      {
          key: 'Our Network',
          text: 'Our Network',
          value: 'ourNetwork',
          icon: 'icon-users',
          onClick: viewOurNetwork,
      },
    // {
    //   key: 'My Nest Questions',
    //   text: 'My Nest Questions',
    //   value: 'myNestQuestions',
    //   icon: 'icon-bookmark',
    // },
    // {
    //   key: 'Saved Nest Questions',
    //   text: 'Saved Nest Questions',
    //   value: 'savedNestQuestions',
    //   icon: 'icon-question',
    // },
    {
      key: 'Settings',
      text: 'Settings',
      value: 'settings',
      icon: 'icon-settings',
      onClick: viewSettingsHandler,
    },
    {
      key: 'Log Out',
      text: 'Log Out',
      value: 'logOut',
      icon: 'icon-log-out',
      onClick: logoutHandler,
    },
  ];

  const signInClickHandler = () => {
    setSideBarOpened(false)
    history.push({ pathname: ROUTES.SIGN_IN, state: { closeForgot: true } });
  };

  const signUpClickHandler = () => {
    setSideBarOpened(false)
    history.push(ROUTES.SIGN_UP);
  };

  return (
    <>
      {isAuth ? (
        <ul className="navbar-profile">
            <li>
                <Messages title="Messages" routePath={ROUTES.CHAT} iconClass="icon-mail" />
            </li>
            <li>
                <Notification title="Notifications" iconClass="icon-bell" />
            </li>
          <li>
            <Dropdown
              fluid
              labeled
              disabled={isPhone}
              icon={false}
              text={() => (
                <div className="avatar">
                  <Avatar url={profileImageUrl} name={fullName} size={40} avatarIcon={avatarIcon} />
                  <span className="icon-chevron-down m-hide" />
                </div>
              )}
            >
              <Dropdown.Menu direction="left">
                {dropdownOptions.map((item) => (
                  <Dropdown.Item key={item.value} {...item} />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <Button onClick={signInClickHandler} className="ui button link">
              Sign In
            </Button>
          </li>
          <li className="no-margin">
            <Button onClick={signUpClickHandler} className="ui button button-rounded-gradient">
              Join now
            </Button>
          </li>
        </ul>
      )}
    </>
  );
};

export default observer(NavbarRight);
