import {errorNotification, errorPromiseCather, toggleLoader} from "utils";
import apiAxios from "axiosConfig";
import qs from "qs";

export const addPost = async (data) => {
  if (data.tags) {
    data.tags = data.tags.map((tag) => ({ id: tag }));
  }

  try {
    return await toggleLoader(() =>
      apiAxios
        .post('/nest/api/posts', data)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const voteForPost = async (postId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .patch(`/nest/api/posts/${postId}/vote/increase`, postId)
        .then((res) => res.data)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};


export const loadPosts = async (params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        // .get(postsURL, {
        .get('/nest/api/posts', {
          params,
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'comma', encode:  true }),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const loadOnePost = async (id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/posts/${id}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification('PostCard loading error');
    console.error(error);
  }
};

export const loadCommentsToPost = async (
  id,
  sortBy = 'createTime',
  sortOrder = 'DESC',
  page = 0
) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(
          `/nest/api/posts/${id}/comments?sort-by=${sortBy}&sort-order=${sortOrder}&page=${page}`
        )
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const loadRepliesToPostComments = async (postId, commentId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(
          `/nest/api/posts/${postId}/comments?sort-by=createTime&sort-order=DESC&parent-id=${commentId}`
        )
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const loadOneCommentToPost = async (postId, commentId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/posts/${postId}/comments/${commentId}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const addComment = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/posts/${data.postId}/comments`, data)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const loadPublicPosts = async () => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get('nest/api/public/posts')
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const deletePost = async (id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .delete(`nest/api/posts/${id}`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const editPost = async (data, id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .put(`/nest/api/posts/${id}`, data)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const deleteComment = async (commentId, postId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .delete(`nest/api/posts/${postId}/comments/${commentId}`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const editComment = async (commentId, postId, data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .put(`nest/api/posts/${postId}/comments/${commentId}`, data)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const voteForComment = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .patch(`/nest/api/posts/${data.postId}/comments/${data.commentId}/vote/increase`, data)
        .then((res) => res.data)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
