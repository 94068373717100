import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Message } from 'semantic-ui-react';
import { NavLink, useLocation } from 'react-router-dom';

import './InfoBetaMessage.scss';
import { useAppStore } from 'stores';
import { ROUTES } from 'utils';

const InfoBetaMessage = () => {
  const refScroll = useRef();
  const { key } = useLocation();
  const { setShowBeta } = useAppStore();
  useEffect(() => {
    if (refScroll.current) {
      refScroll.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [key]);

  return (
    <div ref={refScroll} className="wrap">
      <Message info onDismiss={() => setShowBeta(false)}>
        <Message.Header>
          <div className="info-beta-icon">i</div>
          <div>
            <NavLink
              target="_blank"
              to={ROUTES.TERMS_OF_SERVICE}
              style={{ textDecoration: 'underline' }}
            >
              Terms of Service
            </NavLink>{' '}
            update. If you have any questions or concerns please send us a detailed message to&nbsp;
            <a href="mailto:info@bionabu.com">info@bionabu.com</a>
          </div>
        </Message.Header>
      </Message>
    </div>
  );
};

export default observer(InfoBetaMessage);
