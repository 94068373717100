import React, {Fragment, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useHistory, useLocation} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import Header from 'components/blocks/Header';
import RoutesConfig from 'routes/RoutesConfig';
import Footer from 'components/blocks/Footer/Footer';
import Spinner from 'components/UI/Spinner';
import {ROUTES} from 'utils';
import {useAppStore, useOptionsStore, useUIStore, useUserStore} from 'stores';
import ScrollToTop from 'components/common/ScrollToTop';

import 'react-toastify/dist/ReactToastify.css';
import 'react-slidedown/lib/slidedown.css';
import {LOCALSTORAGE_KEYS} from 'contants/common';
import NotAuthorizedModal from 'components/common/NotAuthorizedModal';
import InfoBetaMessage from 'components/common/InfoBetaMessage';

import 'react-alice-carousel/lib/alice-carousel.css';
import CookiePopup from './components/layout/CookiePopup';
import {initializeGtm} from './utils/helpers';

const App = () => {
    const ref = useRef();
    const {pathname} = useLocation();
    const {token, checkAuth, watchRefreshToken, isWatched, logout} = useUserStore();
    const {sideBarOpened} = useUIStore();
    const {setOffline, setOnline, isOnline, apiError, showBeta} = useAppStore();
    const {loadOptions} = useOptionsStore();
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        const stayLogged = localStorage.getItem(LOCALSTORAGE_KEYS.STAY_LOGGED);
        const sessionLogged = sessionStorage.getItem(LOCALSTORAGE_KEYS.STAY_LOGGED);
        const activeUserId = localStorage.getItem(LOCALSTORAGE_KEYS.ACTIVE_USER_ID);
        if (!isWatched && (!!stayLogged || !!sessionLogged)) {
            watchRefreshToken();
        }

        if (
            ((!token && sessionLogged && !stayLogged) || (!token && stayLogged)) &&
            location.pathname !== ROUTES.CONFIRM_EMAIL
        ) {
            checkAuth()
                .then((res) => {
                    if (res && res.redirect && pathname !== ROUTES.MAIN_PAGE) history.push(ROUTES.SIGN_IN);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else if (activeUserId) {
            checkAuth().finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }

        const syncLogout = (e) => {
            if (e.key === LOCALSTORAGE_KEYS.STAY_LOGGED) {
                if (!e.newValue) logout();
            }
        };

        window.addEventListener('storage', syncLogout);
    }, []);

    useEffect(() => {
        initializeGtm();
        setOnline();
        window.addEventListener('offline', setOffline);
        window.addEventListener('online', setOnline);
        loadOptions();
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
        return () => {
            window.removeEventListener('offline', setOffline);
            window.removeEventListener('online', setOnline);
        };
    }, []);

    if (loading) return <Spinner/>;

    const excludeRoutes = [ROUTES.SIGN_IN, ROUTES.SIGN_UP, ROUTES.CHAT, ROUTES.RESET_PASSWORD];
    const containerSizes = {
        [ROUTES.MAIN_PAGE]: '1110px',
        [ROUTES.MENTEES]: '1110px',
        [ROUTES.MENTOR]: '1110px',
        [ROUTES.COMPANIES]: '1110px',
        [ROUTES.STUDENTS]: '1110px',
        [ROUTES.PROJECT]: '1110px',
        [ROUTES.ACTIVITY]: '1110px',
        [ROUTES.MY_PROJECTS]: '1110px',
        [ROUTES.ABOUT_US]: '1110px',
        [ROUTES.NEST]: '1110px',
        [ROUTES.FIND_EXPERT]: '1110px',
    };
    const containerSizeKey = (pathname) => {
        if (pathname.includes(ROUTES.PROJECT)) {
            return ROUTES.PROJECT;
        }
        if (pathname.includes(ROUTES.ACTIVITY)) {
            return ROUTES.ACTIVITY;
        }
        return pathname;
    };

    return isOnline && !apiError ? (
        <div
            style={{
                width: '100%',
                maxWidth: '100vw',
                overflowX: 'hidden',
                minHeight: '100%',
                overflowY: sideBarOpened ? 'hidden' : 'unset',
            }}
        >
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />
            <NotAuthorizedModal/>
            <ScrollToTop appRef={ref}/>
            {/* {showBeta && <InfoBetaMessage />} */}
            <Header containerWidth={containerSizes[containerSizeKey(pathname)]}/>
            {/* {loading && <Spinner />} */}
            <div ref={ref}>
                <RoutesConfig />
            </div>
            <CookiePopup/>
            {!excludeRoutes.includes(pathname) && (
                <Footer containerWidth={containerSizes[containerSizeKey(pathname)]}/>
            )}
        </div>
    ) : (
        <div className="connection-lost-page">Opps... Connection Lost. Try to refresh page.</div>
    );
};

export default observer(App);
