import React from 'react';
import {observer} from 'mobx-react-lite';
import {Container} from 'semantic-ui-react';
import 'components/blocks/Header/Header.scss';
import Navbar from 'components/blocks/Header/Navbar';
import NavbarRight from 'components/blocks/Header/NavbarRight';
import Logo from 'components/blocks/Header/Logo';
import HiddenMenu from "components/blocks/Header/HiddenMenu/HiddenMenu";

const Header = ({containerWidth}) => (
    <header id="header">
        <Container className="height100" style={containerWidth && {width: containerWidth}}>
            <nav className="nav row height100">
                <div className="navbar-left">
                    <HiddenMenu/>
                    <Logo/>
                </div>
                <div className='navbar-links'>
                    <Navbar/>
                </div>
                <div className="navbar-nav height100">
                    <NavbarRight/>
                </div>
            </nav>
        </Container>
    </header>
);

export default observer(Header);
