import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ModalWrapper from 'components/common/ModalWrapper/ModalWrapper';
import { Button, Form, Modal } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import './ContactUsModal.scss';
import { ContactUsContext, SubmitProjectContext } from 'context/FormContext';
import { ContactUsSchema } from 'utils/validationConfig';
import InputField from 'components/UI/InputField/InputField';
import { CONTACT_US_FIELDS, REGISTRATION_FIELDS, SHAREHOLDER_FIELDS } from 'contants/common';
import TextAreaField from 'components/UI/TextAreaField/TextAreaField';
import { useAppStore } from 'stores';

const ContactUsModal = ({ triggerBtn }) => {
  const [loading, setLoading] = useState(false);
  const { sendContactUsRequest } = useAppStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(ContactUsSchema),
  });

  const modalRef = useRef();

  const closeHandler = () => {
    modalRef.current.click();
  };
  const submitHandler = async (data) => {
    if (data) {
      setLoading(true);
      await sendContactUsRequest(data);
      reset();
      if (modalRef.current) modalRef.current.click();
      setLoading(false);
    }
  };

  return (
    <ModalWrapper
      ref={modalRef}
      Trigger={triggerBtn}
      size="small"
      withoutAuth
      className="contact-us-modal-content-wp"
      unregisterCb={clearErrors}
    >
      <Modal.Header>Contact Us</Modal.Header>
      <Modal.Content>
        <div className="contact-us-modal-content">
          <ContactUsContext.Provider
            value={{
              register,
              errors,
              setValue,
              clearErrors,
              getValues,
              reset,
            }}
          >
            <Form noValidate className="form__body">
              <Form.Group>
                <InputField
                  required
                  label="Your First name"
                  className="width50"
                  name={CONTACT_US_FIELDS.FIRST_NAME}
                  context={ContactUsContext}
                  placeholder="Type you first name here"
                />
                <InputField
                  required
                  label="Your Last name"
                  className="width50"
                  name={CONTACT_US_FIELDS.LAST_NAME}
                  context={ContactUsContext}
                  placeholder="Type you last name here"
                />
              </Form.Group>
              <InputField
                label="Email"
                className="width100"
                name={CONTACT_US_FIELDS.EMAIL}
                required
                context={ContactUsContext}
                placeholder="Type your e-mail"
              />
              <TextAreaField
                rows={4}
                required
                name={CONTACT_US_FIELDS.COMMENT}
                placeholder={`Comments`}
                label="Message"
                context={ContactUsContext}
              />
              <div className="contact-us-actions">
                <Button className="cancel-btn" basic onClick={closeHandler}>
                  Cancel
                </Button>
                <Button
                  disabled={loading}
                  loading={loading}
                  onClick={handleSubmit(submitHandler)}
                  className="send-btn"
                >
                  Send
                </Button>
              </div>
            </Form>
          </ContactUsContext.Provider>
        </div>
      </Modal.Content>
    </ModalWrapper>
  );
};

export default observer(ContactUsModal);
