import {errorNotification, errorPromiseCather, toggleLoader} from "utils";
import apiAxios from "axiosConfig";

export const loadNotifications = async (page = 0, unread = false, group) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`nest/api/notifications?page=${page}&unread=${unread}&count=10&notification-group=${group}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const readOneNotification = async (id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .patch(`nest/api/notifications/read/${id}`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const readAllNotification = async (params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .patch(`nest/api/notifications/read`,params)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const loadArchivedNotificationsCount = async (unread, archived) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`nest/api/notifications/messages?unread=${unread}&archived=${archived}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
