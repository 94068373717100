import {makeAutoObservable, runInAction} from "mobx";
import * as Api from "services";
import {rootStore} from "stores/index";

export default class ConnectionStore {

  expertsFriendsMentors = [];
  expertsFriends = [];
  expertsConnections = null;
  connectionsTotalPages = 0;
  activeFriend = null;

  constructor(rootStore) {
    makeAutoObservable(this, {rootStore: false});
    this.rootStore = rootStore;
  }

  loadFriends = async (expertId, page, count) => {
    const result = await Api.loadFriends(expertId, page, count);
    if (result) {
      runInAction(() => {
        this.expertsConnections = result.totalElements;
        this.connectionsTotalPages = result.totalPages;
        this.expertsFriends = result.content;
      });
    }
  };

  loadFriendsMentors = async (expertId, page, count, permissions) => {
    const result = await Api.loadFriends(expertId, page, count, undefined,permissions);
    if (result) {
      runInAction(() => {
        this.expertsFriendsMentors = result.content;
      });
    }
  };
  getFriend = async (expertId, friendId) => {
    const result = await Api.getFriend(expertId, friendId);
    runInAction(() => {
      this.activeFriend = result || null;
    });
    return result;
  };
  acceptFriendRequest = async (expertId, friendId, response, id) => {
    const status = response ? 'ACTIVE' : 'REJECTED';
    const data = { id, status };
    const result = await Api.acceptFriendRequest(expertId, friendId, data);
  };

  sendFriendRequest = async (expertId, friendId,text) => {

    const data = { friendId, text};
    const result = await Api.sendFriendRequest(expertId, data);
    if (result) {
      this.updateExpertsConnectStatus(friendId)
      this.updateTeamRequestCardConnectStatus(friendId)
      this.updateTeamCardConnectStatus(friendId)
    }
  };

  deleteFriend = async (expertId, friendId) => {
    const result = await Api.deleteFriend(expertId, friendId);
    if (result.status == 200) {
      runInAction(() => {
        this.expertsFriends.filter((f) => f.id !== friendId);
      });
    }
  };
  updateExpertsConnectStatus = (friendId) => {
    this.rootStore.expertsStore.experts[friendId] = {...this.rootStore.expertsStore.experts[friendId],  connectionStatus : "NEW"}
  }
  updateTeamRequestCardConnectStatus = (friendId) => {
    this.rootStore.projectStore.teamRequests = this.rootStore.projectStore.teamRequests.map(expert => {
      if(expert.expert.expertId === friendId) {
        return {
          ...expert,
          connectionStatus : "NEW"
        }
      } else {
        return expert;
      }
    })
  }
  updateTeamCardConnectStatus = (friendId) => {
    this.rootStore.projectStore.teamMembers = this.rootStore.projectStore.teamMembers.map(expert => {
      if(expert.expert?.expertId === friendId) {
        return {
          ...expert,
          connectionStatus : "NEW"
        }
      } else {
        return expert;
      }
    })
  }
  updateActiveChatConnectionStatus = () => {
    runInAction(() => {
      this.rootStore.chatStore.activeContact.personalMessage.status = "REJECTED"
    })
  }
}
