import {makeAutoObservable, runInAction} from "mobx";
import * as Api from "services";
export const paymentStatusMap = {
  PENDING_PAYMENT : {status : "Pending", color : "#99A2AC"},
  CANCELLED : {status : "Canceled", color : "#FF7369"},
  PAID : {status : "Paid", color : "#0EC17C"},
  FAILED: {status : "Failed", color : "#FF7369"},
  TRANSFER_PENDING : {status : "Pending", color : "#99A2AC"},
  REFUNDED : {status : "Refunded", color : "#FF7369"},
}
export default class PaymentsStore {

  payments = []
  filterParams = {
    count : 10
  }
  totalPages = 0;
  isLastPagePayments = false;
  isFirstPagePayments = true;
  constructor(rootStore) {
    makeAutoObservable(this, {rootStore: false});
    this.rootStore = rootStore;
  }
  loadPaymentsHistory = async  () => {
    const response = await Api.loadPaymentsHistory(this.filterParams)
    if (response && response.content) {
      runInAction(() => {
        this.payments = response.content;
        this.totalPages = response.totalPages;
        this.isLastPagePayments = response.last;
        this.isFirstPagePayments = response.first;
        this.filterParams.page = response.number;
      });
    }
  }
  loadNextPagePaymentsHistory = async () => {
    if (!this.isLastPagePayments) {
      this.filterParams.page = this.filterParams.page + 1
      await this.loadPaymentsHistory()
    }
  };
  loadPreviousPagePaymentsHistory = async () => {
    if (!this.isFirstPagePayments ) {
      this.filterParams.page = this.filterParams.page - 1;
      await this.loadPaymentsHistory()
    }
  };
  generatePayPalRedirectLink = async (orderId) => {
    const response = await Api.generatePayPalRedirectLink(orderId)
    if (response?.paymentUrl) {
      return response.paymentUrl
    } else {
      return false;
    }
  }
  cancelOrder = async (orderId) => {
    const response = await Api.cancelOrder(orderId)
  }
}
