import {makeAutoObservable, runInAction, toJS} from 'mobx';
import * as Api from 'services';
import {platformFiltersMap} from 'stores/platform.store';
import {
  AcceptOrRejectJoinTeamRequest, loadProjectsOrders,
  loadTeamMembersList,
  loadTeamRequestsList,
  requestJoinTeam
} from "services";
import {PROJECT_FIELDS} from "contants/common";
import {deleteTeamMember} from "services/TeamApi";
import {
  createTicketOrder,
  generatePayPalRedirectLink,
  loadMyArchivedProjects
} from "services/ProjectApi";

export const formActiveTabMap = {
  main: 'About',
  overview: 'Overview',
  faq: 'FAQ',
  mentor : 'Assign'
};

export const menuTabMap = {
  overview: "Overview",
  faq: "FAQ",
  forum: "Discussion",
  expertsInterest: "Requests",
  team: "My Team",
  dashboard : "Dashboard"
}
export const projectStatusMap = {
  "DRAFT": "Draft",
  "PUBLISHED": "Published",
  "PENDING_APPROVAL": "Pending Approval",
}
export const projectArchivedStatusMap = {
  "PENDING_ARCHIVING": "Pending Archive",
  "ARCHIVED": "Archived",
}
export default class ProjectStore {
  loading = false;
  projects = [];

  activeProject = null;

  activeOrderId = null;
  salesAmount = 0;
  salesAmountCurrencySymbol = "$";
  ticketsSold = 0;
  ticketsTotal =  0;


  total = 0;
  filterParams = {
    [platformFiltersMap.count]: 30,
  };
  isFirstPage = true;
  isLastPage = false;
  totalPages = 0;
  /*project menu*/
  menuActiveTab = menuTabMap.overview;
  /*project form*/

  formActiveTab = formActiveTabMap.main;

  /*my projects*/
  myProjects = [];
  myProjectsLoading = false;

  isFirstPageMyProjects = true;
  isLastPageMyProjects = false;
  totalPagesMyProjects = 0;
  myProjectsFilterParams = {
    "count": 50,
  };
  /*saved projects*/
  savedProjects = [];
  savedProjectsLoading = false;
  isFirstPageSavedProjects = true;
  isLastPageSavedProjects = false;
  totalPagesSavedProjects = 0;
  savedProjectsFilterParams = {
    "count": 50,
  };

  /*Project Forum*/
  commentsLoading = false;
  /*Comments*/

  commentFilterParams = {
    count: 20,
  };
  projectComments = [];
  projectCommentsCount = 0;
  activeProjectCommentsReplies = [];

  /*Replies*/
  commentsCurrentPage = 0;
  commentsIsFirstPage = true;
  commentsIsLastPage = true;
  commentsTotalPages = 0;
  /*Project Team*/

  /*Team request*/
  teamRequests = []
  teamRequestListParams = {
    count: 20
  }
  isFirstPageTeamRequests = true;
  isLastPageTeamRequests = false;
  totalPagesTeamRequests = 0;
  currentPageTeamRequests = 0;
  /*Team members*/
  teamMembers = []
  isFirstPageTeamMembers = true;
  isLastPageTeamMembers = false;
  totalPagesTeamMembers = 0;
  totalTeamMembers = 0;
  teamMembersCurrentPage = 0;
  teamMembersListParams = {
    count: 30
  }
  /*Collaborations*/
  collaborations = [];
  collaborationsLoading = false;
  isFirstPageCollaborations = true;
  isLastPageCollaborations = false;
  totalPagesCollaborations = 0;
  collaborationsFilterParams = {
    count:50,
  };
  /*Orders*/
  orders = [];
  ordersLoading = false;
  isFirstPageOrders = true;
  isLastPageOrders = false;
  currentPageOrders = 0;
  totalPagesOrders = 0;
  ordersFilterParams = {
    count: 30,
  };
  /*my archived projects*/
  myArchivedProjects = [];
  myArchivedProjectsLoading = false;

  isFirstPageMyArchivedProjects = true;
  isLastPageMyArchivedProjects = false;
  totalPagesMyArchivedProjects = 0;
  myArchivedProjectsFilterParams = {
    "count": 50,
  };

  constructor(rootStore) {
    makeAutoObservable(this, {rootStore: false});
    this.rootStore = rootStore;
  }

  /*Projects*/
  loadOneProject = async (id, withView = false) => {
    let result = null;
    if (this.rootStore.userStore.isAuth) {
      result = await Api.loadOneProject(id, withView);
    } else {
      result = await Api.loadOnePublicProject(id, withView);
    }
    if (result && result.id) {
      runInAction(() => {
        this.activeProject = result;
        this.activeProjectCommentsReplies = []
      });
      return true;
    }
    return false;
  };
  loadProjects = async (platformParams = this.filterParams) => {
    this.loading = true;
    platformParams = {...platformParams, ...this.filterParams};
    let response = null;
    if (this.rootStore.userStore.isAuth) {
      response = await Api.loadProjects(platformParams);
    } else {
      response = await Api.loadPublicProjects(platformParams);
    }

    if (response && response.content) {
      runInAction(() => {
        this.projects = response.content;
        this.total = response.totalElements;
        this.totalPages = response.totalPages;
        this.isLastPage = response.last;
        this.isFirstPage = response.first;
        this.filterParams.page = response.number;
      });
    }
    this.loading = false;
  };
  loadMoreProjects = async () => {
    if (!this.isLastPage) {
      this.filterParams.page = this.filterParams.page + 1
    }
    const platformParams = {...this.rootStore.platformStore.platformFilter, ...this.filterParams};
    let response = null;
    if (this.rootStore.userStore.isAuth) {
      response = await Api.loadProjects(platformParams);
    } else {
      response = await Api.loadPublicProjects(platformParams);
    }
    if (response && response.content) {
      runInAction(() => {
        this.projects = [...this.projects, ...response.content];
        this.total = response.totalElements;
        this.totalPages = response.totalPages;
        this.isLastPage = response.last;
        this.isFirstPage = response.first;
        this.filterParams.page = response.number;
      });
    }
  }
  loadNextPage = async () => {
    if (!this.isLastPage) {
      this.filterParams.page = this.filterParams.page + 1
      this.loadProjects(this.rootStore.platformStore.platformFilter);
    }
  };
  loadPreviousPage = async () => {
    if (!this.isFirstPage) {
      this.filterParams.page = this.filterParams.page - 1;
      this.loadProjects(this.rootStore.platformStore.platformFilter);
    }
  };

  addProject = async (data) => {
    data = await this.prepareProjectForm(data);
    const result = await Api.addProject(data);
    if (result && result.id) {
      this.loadProjects();
      return result.id;
    }
  };
  updateProject = async (data, id) => {
    data = await this.prepareProjectForm(data);
    const response = await Api.updateProject(data, id);
    if (response && response.status === 200) {
      return response.data.payload.id
    } else {
      return false;
    }
  };
  deleteProject = async (id) => {
    const res = await Api.deleteProject(id);
    if (res) {
      runInAction(() => {
        if (this.activeProject && this.activeProject.id === id) {
          this.activeProject = null;
        }
        this.projects = this.projects.filter(item => item.id !==id);
      });
    }
  };

  prepareProjectForm = async (data) => {
    if (data.tags) {
      data.tags = data.tags.map((tag) => ({id: tag}));
    }
    if (data.faq) {
      data.faq = data.faq.map((faq) => ({question: faq.question, answer: faq.answer}));
    }
    if (data.activePeriod) {
      if (data.activePeriod.startDateTime) {
        data.activePeriod.startDateTime = new Date(data.activePeriod.startDateTime).toISOString();
      } else {
        data.activePeriod.startDateTime = null;
      }
      if (data.activePeriod.endDateTime) {
        data.activePeriod.endDateTime = new Date(data.activePeriod.endDateTime).toISOString();
      } else {
        data.activePeriod.endDateTime = null;
      }

    }
    if (!data.budget?.amount) {
      delete data.budget
    }
    if (data.mediaKey?.img) {
      data.mediaKey = data.mediaKey.img;
    } else if (data.mediaKey?.file) {
      const formData = new FormData();
      formData.append('media', data.mediaKey.file);
      const res = await Api.uploadProjectImage(formData);

      if (res.resourceKey) {
        data.mediaKey = res.resourceKey;
      }
    }
    if (data[PROJECT_FIELDS.TAB_SETTINGS]) {
      data[PROJECT_FIELDS.TAB_SETTINGS] = data[PROJECT_FIELDS.TAB_SETTINGS].map(item => {
        let newItem = {...item}
        if (item.tabName === "FAQ") {
          newItem = {...newItem, visibilityType: data[PROJECT_FIELDS.FAQ_VIEW]}
        }
        delete newItem['entriesCount']
        return newItem;
      })
    }
    if (data[PROJECT_FIELDS.CO_OWNERS]) {
      data[PROJECT_FIELDS.CO_OWNERS] = [{"expertId": data[PROJECT_FIELDS.CO_OWNERS]}]
    } else  {
      data[PROJECT_FIELDS.CO_OWNERS] = []
    }
    return data;
  };

  /*MyProjects*/
  loadMyProjects = async (expertId = this.rootStore.userStore.user.expertId) => {
    this.myProjectsLoading = true;
    const result = await Api.loadMyProjects(expertId, this.myProjectsFilterParams);
    if (result) {
      runInAction(() => {
        this.myProjects = result.content;
        this.myProjectsFilterParams.page = result.number;
        this.isFirstPageMyProjects = result.first;
        this.isLastPageMyProjects = result.last;
        this.totalPagesMyProjects = result.totalPages;
        this.myProjectsFilterParams.page = result.number;
      });
    }
    this.myProjectsLoading = false;
  }
  loadNextPageMyProjects = async () => {
    if (!this.isLastPageMyProjects) {
      this.myProjectsFilterParams.page = this.myProjectsFilterParams.page + 1;
      await this.loadMyProjects();
    }
  };
  loadPreviousPageMyProjects = async () => {
    if (!this.isFirstPageMyProjects) {
      this.myProjectsFilterParams.page = this.myProjectsFilterParams.page - 1;
      await this.loadMyProjects();
    }
  };
  /*MyArchivedProjects*/
  loadMyArchivedProjects = async (expertId = this.rootStore.userStore.user.expertId) => {
    this.myArchivedProjectsLoading = true;
    const result = await Api.loadMyArchivedProjects(expertId, this.myArchivedProjectsFilterParams);
    if (result) {
      runInAction(() => {
        this.myArchivedProjects = result.content;
        this.myArchivedProjectsFilterParams.page = result.number;
        this.isFirstPageMyArchivedProjects = result.first;
        this.isLastPageMyArchivedProjects = result.last;
        this.totalPagesMyArchivedProjects = result.totalPages;
        this.myArchivedProjectsFilterParams.page = result.number;
      });
    }
    this.myArchivedProjectsLoading = false;
  }
  loadNextPageMyArchivedProjects = async () => {
    if (!this.isLastPageMyArchivedProjects) {
      this.myArchivedProjectsFilterParams.page = this.myArchivedProjectsFilterParams.page + 1;
      await this.loadMyArchivedProjects();
    }
  };
  loadPreviousPageMyArchivedProjects = async () => {
    if (!this.isFirstPageMyArchivedProjects) {
      this.myArchivedProjectsFilterParams.page = this.myArchivedProjectsFilterParams.page - 1;
      await this.loadMyArchivedProjects();
    }
  };
  toggleArchived = async (projectId) => {
    const result = await Api.toggleArchived(projectId);
    if (result.status === 204) {
      this.loadMyProjects()
      this.loadMyArchivedProjects()
    }
  }
  /*SavedProjects*/
  loadSavedProjects = async (expertId = this.rootStore.userStore.user.expertId) => {
    this.savedProjectsLoading = true;
    const result = await Api.loadSavedProjects(expertId, this.savedProjectsFilterParams);
    if (result) {
      runInAction(() => {
        this.savedProjects = result.content;
        this.savedProjectsFilterParams.page = result.number;

        this.isFirstPageSavedProjects = result.first;
        this.isLastPageSavedProjects = result.last;
        this.totalPagesSavedProjects = result.totalPages;
        this.savedProjectsFilterParams.page = result.number;
      });
    }
    this.savedProjectsLoading = false;
  }
  loadNextPageSavedProjects = async () => {
    if (!this.isLastPageSavedProjects) {
      this.savedProjectsFilterParams.page = this.savedProjectsFilterParams.page + 1;

      await this.loadSavedProjects();
    }
  };
  loadPreviousPageSavedProjects = async () => {
    if (!this.isFirstPageSavedProjects) {
      this.savedProjectsFilterParams.page = this.savedProjectsFilterParams.page - 1;
      await this.loadSavedProjects();
    }
  };

  saveToMyProjects = async (projectId) => {
    const result = await Api.saveToMyProjects(projectId);
    if (result.status === 204) {
      runInAction(() => {
        this.projects =this.projects.map(item => {
          if (item.id ===projectId) {
            return {...item, savedToFavourites : !item.savedToFavourites};
          } else {
            return item;
          }
        })
      })
    }
  }

  /*Project Forum*/

  /*Comments*/

  loadProjectComments = async (projectId, page =0) => {
    runInAction(() => {
      this.commentFilterParams = {
        ...this.commentFilterParams,
        page: page
      };
    })
    const response = await Api.loadProjectComments(projectId, this.commentFilterParams);
    runInAction(() => {
      this.projectComments = response.content;
      this.commentsIsFirstPage = response.first;
      this.commentsIsLastPage = response.last;
      this.commentsCurrentPage = response.number;
      this.commentsTotalPages = response.totalPages;
      this.projectCommentsCount = response.totalElements
    })
  }
  createProjectComment = async (projectId, data) => {
    const result = await Api.createProjectComment(projectId, data);
    if (result) {
      runInAction(() => {

        if (data.parentId) {
          this.loadProjectComments(projectId, this.commentsCurrentPage)
          this.loadProjectCommentReplies(projectId, data.parentId)
        } else {
          this.loadProjectComments(projectId)
          this.loadOneProject(projectId)
        }
      })
    }
  }
  editProjectComment = async (projectId, commentId, data) => {
    const {parentId} = data;
    const result = await Api.editProjectComment(projectId, commentId, data);

    if (result) {
      const editedComment = await Api.loadOneCommentToProject(projectId, commentId);
      if (data.parentId) {
        this.activeProjectCommentsReplies = this.activeProjectCommentsReplies.map(item => {
          if (item.parentId === parentId) {
            const newReplies = item.replies.map(reply => {
              if (reply.id === commentId) {
                return editedComment;
              } else {
                return reply;
              }
            })
            return {...item, replies: newReplies}
          } else {
            return item
          }
        })
      } else {
        this.projectComments = this.projectComments.map((item) => item.id === commentId ? editedComment : item);
      }
    }
  };
  deleteProjectComment = async (projectId, commentId, parentId) => {
    const res = await Api.deleteProjectComment(projectId, commentId);
    if (res) {
      runInAction(() => {
        if (parentId) {
          this.loadProjectCommentReplies(projectId, parentId)
        } else {
          this.loadProjectComments(projectId)
          this.activeProject = {
            ...this.activeProject,
            answersCount: this.activeProject.answersCount - 1
          }
        }
      });
    }
  };

  /*Replies*/
  loadProjectCommentReplies = async (projectId, commentId, page = 0, count = 5) => {
    const result = await Api.loadProjectCommentReplies(projectId, commentId, page, count);
    if (result && result.content) {
      runInAction(() => {
        const loadedBeforeReplies = this.activeProjectCommentsReplies.find(
          (c) => c.parentId === commentId
        );
        if (loadedBeforeReplies) {
          this.activeProjectCommentsReplies.forEach((comment) => {
            if (comment.parentId === commentId) {
              comment.replies = result.content;
              comment.first = result.first;
              comment.last = result.last;
              comment.number = result.number;
              comment.totalElements = result.totalElements;
            }
          });
        } else {
          this.activeProjectCommentsReplies = [
            ...this.activeProjectCommentsReplies,
            {
              parentId: commentId, replies: result.content,
              first: result.first,
              last: result.last,
              number: result.number,
              totalElements: result.totalElements
            },
          ];
        }
      });
    }
  }
  loadProjectCommentRepliesNextPage = async (projectId, commentId) => {
    const loadedComment = this.getLoadedProjectCommentRepliesData(commentId);
    const last = loadedComment.last;
    const currentPage = loadedComment.number;
    if (!last) {
      await this.loadProjectCommentReplies(projectId, commentId, currentPage + 1)
    }
  }
  loadProjectCommentRepliesPreviousPage = async (projectId, commentId) => {
    const loadedComment = this.getLoadedProjectCommentRepliesData(commentId);
    const first = loadedComment.first;
    const currentPage = loadedComment.number;
    if (!first) {
      await this.loadProjectCommentReplies(projectId, commentId, currentPage - 1)
    }
  }

  getLoadedProjectCommentRepliesData = (parentId) => {
    return this.activeProjectCommentsReplies.find(comment => comment.parentId === parentId)
  }


  voteForProjectComment = async (projectId, commentId) => {
    const vote = await Api.voteForProjectComment(projectId, commentId);
    const loadedComment = await Api.loadOneCommentToProject(projectId, commentId);
    const {parentId} = loadedComment;
    if (parentId) {
      runInAction(() => {
        this.activeProjectCommentsReplies = this.activeProjectCommentsReplies.map(item => {
          if (item.parentId === parentId) {
            const newReplies = item.replies.map(reply => {
              if (reply.id === commentId) {
                return loadedComment;
              } else {
                return reply;
              }
            })
            return {...item, replies: newReplies}
          } else {
            return item
          }
        })
      })
    } else {
      runInAction(() => {
        this.projectComments = this.projectComments.map((item) => {
          if (item.id === commentId) {
            return loadedComment;
          } else {
            return item;
          }
        });

      });
      this.loadSavedProjects();
    }
  }

  /*Project Team*/

  loadProjectTeamRequestsList = async (projectId,page =0) => {
    runInAction(() => {
      this.teamRequestListParams = {...this.teamRequestListParams, page : page}
    })
    const response = await Api.loadTeamRequestsList(projectId, this.teamRequestListParams);
    if (response) {
      runInAction(() => {
        this.teamRequests = response.content
        this.totalPagesTeamRequests = response.totalPages;
        this.teamRequestListParams.page = response.number;
        this.currentPageTeamRequests = response.number;
        this.isLastPageTeamRequests = response.last;
        this.isFirstPageTeamRequests = response.first;
      })
    }
  }
  loadProjectTeamMembersList = async (projectId, page = 0) => {
    runInAction(() => {
      this.teamMembersListParams = { ... this.teamMembersListParams, page : page};
    })
    const response = await Api.loadTeamMembersList(projectId, this.teamMembersListParams);
    if (response) {
      runInAction(() => {
        this.teamMembers = response.content
        this.totalPagesTeamMembers = response.totalPages;
        this.teamMembersListParams.page = response.number;
        this.isLastPageTeamMembers = response.last;
        this.totalTeamMembers = response.totalElements;
        this.teamMembersCurrentPage = response.number;
        this.isFirstPageTeamMembers = response.first;
      })
    }
  }
  loadNextPageTeamMembers = async (projectId) => {
    if (!this.isLastPageTeamMembers) {
      this.teamMembersListParams.page = this.teamMembersListParams.page + 1;
      await this.loadProjectTeamMembersList(projectId);
    }

  }
  loadPreviousTeamMembers = async (projectId) => {
    if (!this.isFirstPageTeamMembers) {
      this.teamMembersListParams.page = this.teamMembersListParams.page - 1;
      await this.loadProjectTeamMembersList(projectId);
    }
  }
  requestJoinProjectTeam = async (projectId, data) => {
    const response = await Api.requestJoinTeam(projectId, data);
    if (response.status === 204) {
      await this.loadOneProject(projectId)
    }
  }
  AcceptOrRejectJoinTeamRequest = async (projectId, expertId, status) => {
    const data = {
      teamStatus: status
    }
    const response = await Api.AcceptOrRejectJoinTeamRequest(projectId, expertId, data)
    if (response.status === 204) {
      await this.loadProjectTeamRequestsList(projectId)
      await this.loadOneProject(projectId)

    }
  }
  deleteTeamMember = async (expertId, projectId) => {
    const response = await Api.deleteTeamMember(expertId, projectId);
    if (response.status === 204) {
      await this.loadProjectTeamMembersList(projectId)
      await this.loadOneProject(projectId)
    }
  }
  leaveTeam = async (projectId) => {
    const response = await Api.leaveTeam(projectId);
    if (response.status === 204) {
      await this.loadOneProject(projectId)
    }
  }
  /*---------------------------------------------------------------------------------------------*/
  /*ticket payment*/
  createTicketOrder = async (projectId)  => {
    const response = await Api.createTicketOrder(projectId)
    runInAction(() => {
      if (response?.id) {
        this.activeOrderId = response.id
      }
    })
  }
  setTicketStatus = (value) => {
    runInAction(() => {
      this.activeProject ={...this.activeProject, ticketInfo : {...this.activeProject.ticketInfo, currentExpertTicketStatus : value}}
    })
  }
  loadProjectsOrders = async (projectId, page=0) => {
    runInAction(() => {
      this.ordersFilterParams = {...this.ordersFilterParams, page : page}
    })
    const response = await Api.loadProjectsOrders(projectId, this.ordersFilterParams)
    if (response) {
      runInAction(() => {
        this.orders = response.orders.content;
        this.ordersFilterParams.page = response.orders.number;
        this.isFirstPageOrders = response.orders.first;
        this.isLastPageOrders = response.orders.last;
        this.totalPagesOrders = response.orders.totalPages;
        this.currentPageOrders = response.orders.number;
        this.salesAmount = response.salesAmount ;
        this.salesAmountCurrencySymbol = response.salesAmountCurrencySymbol;
        this.ticketsSold = response.ticketsSold;
        this.ticketsTotal =  response.ticketsTotal;
      });
    }
  }
  /*Collobarations*/
  loadCollaborations = async (expertId = this.rootStore.userStore.user.expertId) => {
    this.collaborationsLoading = true;
    const result = await Api.loadCollaborations(expertId, this.collaborationsFilterParams);
    if (result) {
      runInAction(() => {
        this.collaborations = result.content;
        this.collaborationsFilterParams.page = result.number;
        this.isFirstPageCollaborations = result.first;
        this.isLastPageCollaborations = result.last;
        this.totalPagesCollaborations = result.totalPages;
      });
    }
    this.collaborationsLoading = false;
  }
  loadNextPageCollaborations = async () => {
    if (!this.isLastPageCollaborations) {
      this.collaborationsFilterParams.page = this.collaborationsFilterParams.page + 1;
      await this.loadCollaborations();
    }
  };
  loadPreviousPageCollaborations = async () => {
    if (!this.isFirstPageCollaborations) {
      this.collaborationsFilterParams.page = this.collaborationsFilterParams.page - 1;
      await this.loadCollaborations();
    }
  };
  /*---------------------------------------------------------------------------------------------*/
  /*project form*/
  setFormActiveTab = (value) => {
    runInAction(() => {
      this.formActiveTab = value;
    })
  }
  setMenuActiveTab = (value) => {
    runInAction(() => {
      this.menuActiveTab = value;
    })
  }
  setActiveProject = (value) => {
    runInAction(() => {
      this.activeProject = value;
    })
  }
  setCurrentPageProjectDefaultFilterParams(filterParams) {
    runInAction(() => {
      this.filterParams = filterParams;
    })
  }
  setActiveOrder = (value) => {
    this.activeOrderId =value
  }
}


