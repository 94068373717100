import {makeAutoObservable, runInAction} from 'mobx';
import * as Api from 'services';
import {isNull, isUndefined, isArray} from 'lodash';
import {BOOLEAN_FIELDS_MAP} from 'contants/common';
import {platformFiltersMap} from "stores/platform.store";
import {loadExpertsList} from "services";

export default class ExpertsStore {
  expertsIds = [];
  showenExpertsIds = [];
  experts = {};

  totalExperts = 0;

  moreLoading = false;

  loading = false;

  maxPageCount =0;

  filterParams = {
    count: 25,
  };

  modalFilterParams = this.filterParams;

  activePage = 0;
  currentPage = 0;
  totalPages = 0;

  constructor(rootStore) {
    makeAutoObservable(this, {rootStore: false});
    this.rootStore = rootStore;
  }

  // Computed
  get getTags() {
    const keys = Object.keys(this.filterParams);
    let tagsArray = [];

    keys.forEach((key) => {
      const isArray = Array.isArray(this.filterParams[key]);
      if (isArray) {
        tagsArray = tagsArray.concat(
          this.filterParams[key]
            .filter(() => this.rootStore.optionsStore.optionIdMap.get(key))
            .map((paramId) => {
              const item = this.rootStore.optionsStore.optionIdMap
                .get(key)
                .find((elem) => elem.id === paramId);
              if (item) {
                return {
                  ...item,
                  key,
                };
              }
              return undefined;
            })
        );
      } else if (Object.keys(BOOLEAN_FIELDS_MAP).includes(key)) {
        if (key === 'searchByMembership') {
          this.filterParams[key] === true &&
          tagsArray.push({
            id: key,
            name: `${BOOLEAN_FIELDS_MAP[key]}: ${this.filterParams[key] === true ? 'PRO' : ''}`,
            key,
          });
        } else if (!isUndefined(this.filterParams[key])) {
          tagsArray.push({
            id: key,
            name: `${BOOLEAN_FIELDS_MAP[key]}: ${this.filterParams[key] === true ? 'yes' : 'no'}`,
            key,
          });
        }
      }
    });
    return tagsArray;
  }

  get filterStatus() {
    const keys = Object.keys(this.filterParams);

    return keys.some(
      (key) =>
        this.rootStore.optionsStore.optionIdMap.has(key) ||
        Object.keys(BOOLEAN_FIELDS_MAP).includes(key)
    );
  }

  // Actions

  loadExperts = async () => {
    this.loading = true;
    let res = null;
    if (this.rootStore.userStore.isAuth) {
      res = await Api.loadExpertsList(this.filterParams);
    } else {
      res = await Api.loadPublicExpertsList();
    }
    if (res && res.content) {
      runInAction(() => {
        this.maxPageCount = res.totalPages;
        this.totalExperts = res.totalElements;
        this.expertsIds = res.content.map((expert) => expert.expertId);
        this.showenExpertsIds = this.expertsIds;
        this.experts = res.content.reduce((acc, expert) => {
          acc[expert.expertId] = expert;
          return acc;
        }, {});
      });
    }
    if (res && res.totalPages) this.isLastPage = res.number >= res.totalPages - 1;
    this.loading = false;
  };
  loadNextPage = async () => {
    if (this.currentPage < this.maxPageCount) {
      this.currentPage = this.currentPage + 1;
      if (this.activePage < this.currentPage) {
        await this.loadMoreExperts()
      }
      const start = this.currentPage * this.filterParams.count;
      const end = (this.currentPage + 1) * this.filterParams.count;
      if (end <= this.expertsIds.length) {
        this.showenExpertsIds = this.expertsIds.slice(start,end);
      } else {
        this.showenExpertsIds = this.expertsIds.slice(start
          , this.expertsIds.length);
      }
    }
  };
  loadPreviousPage = async () => {
    this.currentPage = this.currentPage - 1;
    const start = this.currentPage * this.filterParams.count;
    const end = (this.currentPage + 1) * this.filterParams.count;
    this.showenExpertsIds = this.expertsIds.slice(start,end);
  };


  loadMoreExperts = async () => {
    this.moreLoading = true;
    const nextPage = this.activePage + 1;
    const params = {
      ...this.filterParams,
      page: nextPage,
    };
    let res = null;
    if (this.maxPageCount > nextPage) {
      if (this.rootStore.userStore.isAuth) {
        res = await Api.loadExpertsList(params);
      } else {
        res = await Api.loadPublicExpertsList(params);
      }
    }
    if (res && res.content) {
      const newExpertsIds = res.content.map((expert) => expert.expertId);
      const newExperts = res.content.reduce((acc, expert) => {
        acc[expert.expertId] = expert;
        return acc;
      }, {});
      runInAction(() => {
        this.activePage = nextPage;
        this.expertsIds = [...this.expertsIds, ...newExpertsIds];
        this.experts = {...this.experts, ...newExperts};
      });
    }
    if (res && res.totalPages) this.isLastPage = res.number >= res.totalPages - 1;
    this.moreLoading = false;
  };

  searchExperts = () => {
    runInAction(() => {
      this.currentPage = 0;
      this.activePage = 0;
      this.loadExperts();
    });
  };
  cleanFilterField = (value, field, params) => {
    if (field === 'searchByMembership' && !value) {
      delete params[field];
    } else if (Object.keys(BOOLEAN_FIELDS_MAP).includes(field) && isUndefined(value)) {
      delete params[field];
    } else if (params[field].length < 1) {
      delete params[field];
    }
  };
  setFiltersParams = (value, field, withSearch = false) => {
    if (!isNull(value) && !isUndefined(value) && field) {
      this.filterParams[field] = value;
    }
    // todo: temporarily
    if (Object.keys(BOOLEAN_FIELDS_MAP).includes(field)) {
      this.filterParams[field] = value;
    }
    this.setModalFiltersParams(value, field);
    this.cleanFilterField(value, field, this.filterParams);
    if (withSearch) this.searchExperts();
  };

  setSortOrder = () => {
    if (this.filterParams['sort-order'] == 'ASC' || !this.filterParams['sort-order']) {
      this.filterParams['sort-order'] = 'DESC';
    } else if (this.filterParams['sort-order'] == 'DESC') {
      this.filterParams['sort-order'] = 'ASC';
    }
  };

  resetSort = () => {
    delete this.filterParams['sort-by'];
    delete this.filterParams['sort-order'];
  };

  resetFiltersParams = () => {
    this.filterParams = {count: 10};
  };

  resetTag = (tag) => {
    const {id, key} = tag;
    const deleteTag = (param) => {
      const isArray = Array.isArray(param[key]);
      if (isArray) {
        param[key] = param[key].filter((item) => item !== id);
        if (param[key].length < 1) {
          delete param[key];
        }
      } else if (Object.keys(BOOLEAN_FIELDS_MAP).includes(key)) {
        delete param[key];
      }
    };

    deleteTag(this.filterParams);
    deleteTag(this.modalFilterParams);
  };

  setModalFiltersParams = (value, field) => {
    if (!isNull(value) && !isUndefined(value) && field) {
      this.modalFilterParams[field] = value;
    }
    // todo: temporarily
    if (Object.keys(BOOLEAN_FIELDS_MAP).includes(field)) {
      this.modalFilterParams[field] = value;
    }
    this.cleanFilterField(value, field, this.modalFilterParams);
  };

  applyModalFilters = async () => {
    this.filterParams = {...this.modalFilterParams};
  };

  resetModalFiltersParams = () => {
    this.modalFilterParams = {count: 10};
  };
}
