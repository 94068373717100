import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import Avatar from 'components/UI/Avatar/Avatar';
import { ROUTES } from 'utils';

const NavbarItemPop = ({ iconClass, routePath, title, dropdownOptions }) => {
  const { pathname } = useLocation();
  const isActive = !!dropdownOptions.find((item) => item.pathname === pathname);
  return (
    <Dropdown
      fluid
      labeled
      icon={false}
      open={false}
      simple
      className={`baseline-grid dropdown-wp ${isActive && 'active-with-pop-up'}`}
      text={() => (
        <>
          <span className={iconClass} />
          <span className="m-hide pre-wrap margin-top">{title}</span>
        </>
      )}
    >
      <Dropdown.Menu direction="left">
        {dropdownOptions.map((item) => (
          <Dropdown.Item
            key={item.pathname}
            {...item}
            style={{
              lineHeight: '33px',
              minWidth: '150px',
              color: item.pathname === pathname ? '#0ec17c' : '#373f41',
            }}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

NavbarItemPop.propTypes = {
  iconClass: PropTypes.string.isRequired,
  routePath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default observer(NavbarItemPop);
