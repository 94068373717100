import { makeAutoObservable, runInAction } from 'mobx';
import * as Api from 'services';

const optionsMap = [
  'badges',
  'countries',
  'digitalHealthSkills',
  'experiences',
  'expertAvailabilities',
  'expertRoles',
  'expertiseAreas',
  'industries',
  'languages',
  'occupations',
  'seniorityLevels',
  'specialities',
];

export default class OptionsStore {
  /*
  *
badges
countries,
digitalHealthSkills
experiences
expertAvailabilities
expertRoles
expertiseAreas
industries
languages
occupations
seniorityLevels
specialities
*  */

  badges = [];
  countries = [];
  digitalHealthSkills = [];
  experiences = [];
  expertAvailabilities = [];
  expertRoles = [];
  expertiseAreas = [];
  industries = [];
  languages = [];
  occupations = [];
  seniorityLevels = [];
  specialities = [];
  willingnessTravels = [];
  tags = [];
  sortBy = [
    { title: 'name', param: 'firstName' },
    { title: 'last name', param: 'lastName' },
    { title: 'date', param: 'expertId' },
  ];
  sortByNestPage = [
    { title: 'date', param: 'createTime' },
  ];
  projectSubtypes = [];
  activityTypes = [];
  currenciesSymbols = [];
  optionIdMap = new Map();
  optionPlatformMap = new Map();
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  // Computed

  // Actions

  loadOptions = async () => {
    const response = await Api.loadOptions();
    if (response) {
      runInAction(() => {
        Object.keys(response).forEach((key) => {
          this[key] = response[key];
        });
        this.optionIdMap = new Map([
          ['industryIds', this.industries],
          ['expertiseAreaIds', this.expertiseAreas],
          ['expertRoleIds', this.expertRoles],
          ['digitalHealthSkillIds', this.digitalHealthSkills],
          ['specialityIds', this.specialities],
          ['locationIds', this.countries],
          ['languageIds', this.languages],
          ['experienceIds', this.experiences],
          ['seniorityLevelIds', this.seniorityLevels],
          ['availabilityIds', this.expertAvailabilities],
          ['willingnessTravelIds', this.willingnessTravels],
        ]);
        this.optionPlatformMap = new Map([
          ['industries', this.industries],
          ['areas', this.expertiseAreas],
          ['tags', this.tags],
        ]);
        this.currenciesSymbols = this.currenciesSymbols.map((item, index) => {return {
          id : item,
          name : item
        }})
      });
    }
  };
}
