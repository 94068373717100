import {errorNotification, errorPromiseCather, toggleLoader} from "utils";
import apiAxios from "axiosConfig";
import qs from "qs";


export const loadTeamRequestsList = async (projectId, params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/projects/${projectId}/team/requests`, {
          params,
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'comma', encode:  true }),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const loadTeamMembersList = async (projectId, params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/projects/${projectId}/team`, {
          params,
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'comma', encode:  true }),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const requestJoinTeam = async (projectId, data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/projects/${projectId}/team`, data)
        .then((res) => res)
        .catch((error) => {
          errorPromiseCather(error);
        })
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const AcceptOrRejectJoinTeamRequest = async (projectId,expertId,  data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/experts/${expertId}/projects/${projectId}/team`, data)
        .then((res) => res)
        .catch((error) => {
          console.log(error);
          errorPromiseCather(error);
        })
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const deleteTeamMember = async (expertId, projectId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .delete(`/nest/api/experts/${expertId}/projects/${projectId}/team`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const leaveTeam = async (projectId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .delete(`/nest/api/projects/${projectId}/team/leave`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
