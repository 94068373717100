import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import 'components/blocks/Header/Header.scss';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'utils';
import { useUserStore } from 'stores';

const HiddenMenuSubItem = ({ iconClass, title, active, needAuth, subMenu }) => {
  const { isAuth } = useUserStore();
  const display = (needAuth && isAuth) || !needAuth;
  const [open, setOpen] = useState(false);
  return (
    <>
      {display && (
        <>
          <div className={`hidden-menu-item ${active && 'active'}`} onClick={() => setOpen(!open)}>
            <div className="item-content">
              <span className={iconClass} />
              <div>{title}</div>
            </div>
            <span
              className="icon-array-next"
              style={{ transform: open ? 'rotate(90deg)' : 'rotate(0)', transition: '0.5s' }}
            />
          </div>
          {subMenu.map((item) => (
            <NavLink
              className={`hidden-menu-item ${active && 'active'}`}
              to={item.routePath}
              onClick={item.onClick}
              style={{ display: open ? 'flex' : 'none' }}
            >
              <div className="item-content">
                <div>{item.title}</div>
              </div>
            </NavLink>
          ))}
        </>
      )}
    </>
  );
};
export default observer(HiddenMenuSubItem);
