import React from 'react';
import {observer} from 'mobx-react-lite';
import {NavLink, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

const NavbarItem = ({iconClass, routePath, title}) => {
    return (
        <NavLink className={`nav-item`} to={routePath}>
            {/* <span className={iconClass} /> */}
            <span className="m-hide pre-wrap margin-top">{title}</span>
        </NavLink>
    )
};

NavbarItem.propTypes = {
    iconClass: PropTypes.string.isRequired,
    routePath: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default observer(NavbarItem);
