import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal } from 'semantic-ui-react';
import CloseButton from '../../UI/CloseButton/CloseButton';
import './CookiePreferences.scss';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../utils';
import CookieSingleItem from './components/CookieSingleItem';
import { useUserStore } from '../../../stores';
import TagManager from 'react-gtm-module';
import { postCookieDate } from '../../../services';
import { cookiePreferencesConfig } from './consts';
import {
  getCookiePreferencesValues,
  setOnlyStrictlyCookies,
  setSelectedCookiePreferences,
} from '../../layout/CookiePopup/cookie-helper';

const CookiePreferences = ({ show, onClose, onCloseRoot }) => {
  const [analyticsCookie, setAnalyticsCookie] = useState(
    getCookiePreferencesValues()?.analyticsCookie
  );
  const [targetingCookie, setTargetingCookie] = useState(
    getCookiePreferencesValues()?.targetingCookie
  );
  const [functionalCookie, setFunctionalCookie] = useState(
    getCookiePreferencesValues()?.functionalCookie
  );
  const { user, setCookieDate, isAuth } = useUserStore();

  const submitHandler = async (activateAnalytics = true) => {
    const values = {
      strictlyNecessaryCookies: true,
      analyticsCookie,
      functionalCookie,
      targetingCookie,
    };

    setSelectedCookiePreferences(values);

    if (activateAnalytics && analyticsCookie && process.env.REACT_APP_GA_TRACKING_CODE) {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GA_TRACKING_CODE,
      };
      TagManager.initialize(tagManagerArgs);
    }

    if (isAuth && user.expertId && !user.cookiesPolicyAcceptTime) {
      postCookieDate(user.expertId);
      setCookieDate();
    }

    onClose();
    onCloseRoot();
  };

  const rejectAllHandler = () => {
    setAnalyticsCookie(false);
    setTargetingCookie(false);
    setFunctionalCookie(false);
    setOnlyStrictlyCookies();

    if (isAuth && user.expertId && !user.cookiesPolicyAcceptTime) {
      postCookieDate(user.expertId);
      setCookieDate();
    }

    onClose();
    onCloseRoot();
  };

  return (
    <Modal className="cookie-preferences" onClose={onClose} open={show}>
      <CloseButton onClose={onClose} />
      <Modal.Header>Cookie Preferences</Modal.Header>
      <Modal.Content className="cookie-preferences-content" scrolling>
        <div className="">
          <p className="cookie-preferences-text">
            When you visit our website, it may store or retrieve information on your browser, mostly
            in the form of cookies. This information might be about you, your preferences or your
            device and is mostly used to make the website work as you expect it to. The information
            does not usually directly identify you, but it can give you a more personalised web
            experience. Because we respect your right to privacy, you can choose not to allow some
            types of cookies. Click on the below categories to change our default settings. Please
            note, blocking some types of cookies may impact your experience of our website and the
            services we are able to offer.
          </p>
          <p className="cookie-preferences-text">
            For more information, see our{' '}
            <Link className="cookie-preferences-link" to={ROUTES.COOKIE_POLICY}>
              Cookies Policy.
            </Link>
          </p>
          <p className="cookie-preferences-title">Manage Consent Preferences by Category</p>
          <CookieSingleItem
            title={cookiePreferencesConfig.strictlyNecessaryCookies.title}
            description={cookiePreferencesConfig.strictlyNecessaryCookies.description}
            isAlwaysActive
          />
          <CookieSingleItem
            title={cookiePreferencesConfig.analyticsCookies.title}
            description={cookiePreferencesConfig.analyticsCookies.description}
            onChange={() => setAnalyticsCookie((prev) => !prev)}
            value={analyticsCookie}
          />
          <CookieSingleItem
            title={cookiePreferencesConfig.functionalCookies.title}
            description={cookiePreferencesConfig.functionalCookies.description}
            onChange={() => setFunctionalCookie((prev) => !prev)}
            value={functionalCookie}
          />
          <CookieSingleItem
            title={cookiePreferencesConfig.targetingCookies.title}
            description={cookiePreferencesConfig.targetingCookies.description}
            onChange={() => setTargetingCookie((prev) => !prev)}
            value={targetingCookie}
          />
        </div>
      </Modal.Content>
      <div className="cookie-preferences-actions">
        <Button onClick={rejectAllHandler} basic className="reject">
          Reject All
        </Button>
        <Button onClick={submitHandler}>Confirm My Choices</Button>
      </div>
    </Modal>
  );
};

export default observer(CookiePreferences);
