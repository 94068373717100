import { errorNotification, errorPromiseCather, toggleLoader } from 'utils';
import apiAxios from 'axiosConfig';
import qs from 'qs';

export const loadExpertsList = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`nest/api/experts/profiles`, {
          params: data,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'comma', encode:  true });
          },
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const loadPublicExpertsList = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`nest/api/public/experts/profiles`, {
          params: data,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'comma', encode:  true });
          },
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

