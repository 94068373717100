import React, { lazy, Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConfirmEmail } from 'modules/SignUp/components/ConfrimEmail';
import { ROUTES } from 'utils';
import Spinner from 'components/UI/Spinner';

const SignInPage = lazy(() => import('pages/SignInPage'));
const SignUpPage = lazy(() => import('pages/SignupPage'));
const NestPage = lazy(() => import('pages/NestPage'));
const MembershipPage = lazy(() => import('pages/MembershipPage'));
const MessagesPage = lazy(() => import('pages/MessagesPage'));
const ChatPage = lazy(() => import('pages/ChatPage'));
const FindExpertPage = lazy(() => import('pages/FindExpertPage'));
const TopicPage = lazy(() => import('pages/PostPage'));
const ProfilePage = lazy(() => import('pages/ProfilePage'));
const PublicProfilePage = lazy(() => import('pages/PublicProfilePage'));
const ResetPasswordPage = lazy(() => import('pages/ResetPasswordPage'));
const PrivacyPolicyPage = lazy(() => import('pages/PrivacyPolicyPage'));
const CookiePolicyPage = lazy(() => import('pages/CookiePolicyPage'));
const TermsOfServicePage = lazy(() => import('pages/TermsOfServicePage'));
const SettingsPage = lazy(() => import('pages/SettingsPage'));
const LandingPage = lazy(() => import('pages/LandingPage'));
const ConnectionsPage = lazy(() => import('pages/ConnectionsPage'));
const HowItWorksPage = lazy(() => import('pages/HowItWorksPage'));
const FAQPage = lazy(() => import('pages/FAQPage'));
const AboutUsPage = lazy(() => import('pages/AboutUsPage'));
const ActivityPage = lazy(() => import('pages/ActivityPage'));
const ProjectPage = lazy(() => import('pages/ProjectPage'));
const ProjectOwnerViewPage = lazy(() => import('pages/ProjectOwnerViewPage'));
const MyListingsPage = lazy(() => import('pages/MyListingsPage'));
const EditProjectPage = lazy(() => import('pages/EditProjectPage'));
const EditActivityPage = lazy(() => import('pages/EditActivityPage'));
const PaymentsHistoryPage = lazy(() => import('pages/PaymentsHistoryPage'));
const MentorPage = lazy(() => import('pages/MentorPage'));
const MenteesPage = lazy(() => import('pages/MentessPage'));
const CompaniesPage = lazy(() => import('pages/CompaniesPage'));
const StudentsPage = lazy(() => import('pages/StudentsPage'));
const OurMentorsPage = lazy(() => import('pages/OurMentorsPage'));
const RoutesConfig = () => (
  <Suspense
    fallback={
      <div className="page-spinner">
        <Spinner />
      </div>
    }
  >
    <Switch>
      <Route exact path={ROUTES.MAIN_PAGE} component={LandingPage} />
      <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route exact path={ROUTES.FIND_EXPERT} component={FindExpertPage} />
      <Route exact path={ROUTES.ABOUT_US} component={AboutUsPage} />
      <Route exact path={ROUTES.MESSAGES} component={MessagesPage} />
      <Route exact path={ROUTES.CHAT} component={ChatPage} />
      <Route exact path={ROUTES.NEST} component={NestPage} />
      <Route exact path={`${ROUTES.NEST}/:postId`} component={TopicPage} />
      <Route exact path={`${ROUTES.ACTIVITY}/:activityId`} component={ActivityPage} />
      <Route exact path={`${ROUTES.EDIT_ACTIVITY}/:activityId`} component={EditActivityPage} />
      <Route exact path={`${ROUTES.PROJECT}/:projectId`} component={ProjectPage} />
      <Route
        exact
        path={`${ROUTES.PROJECT_OWNER_VIEW}/:projectId`}
        component={ProjectOwnerViewPage}
      />
      <Route exact path={`${ROUTES.EDIT_PROJECT}/:projectId`} component={EditProjectPage} />
      <Route exact path={`${ROUTES.MY_PROJECTS}`} component={MyListingsPage} />
      <Route path={`${ROUTES.PROFILE}/:expertId`} component={ProfilePage} />
      <Route exact path={`${ROUTES.PUBLIC_PROFILE}/:expertId`} component={PublicProfilePage} />
      <Route exact path={`${ROUTES.RESET_PASSWORD}`} component={ResetPasswordPage} />
      <Route exact path={`${ROUTES.CONFIRM_EMAIL}`} component={ConfirmEmail} />
      <Route exact path={`${ROUTES.PRIVACY_POLICY}`} component={PrivacyPolicyPage} />
      <Route exact path={`${ROUTES.COOKIE_POLICY}`} component={CookiePolicyPage} />
      <Route exact path={`${ROUTES.TERMS_OF_SERVICE}`} component={TermsOfServicePage} />
      <Route exact path={`${ROUTES.SETTINGS}`} component={SettingsPage} />
      <Route exact path={`${ROUTES.CONNECTIONS}`} component={ConnectionsPage} />
      <Route exact path={`${ROUTES.PAYMENTS_HISTORY}`} component={PaymentsHistoryPage} />
      {/* <Route exact path={`${ROUTES.HOW_IT_WORKS}`} component={HowItWorksPage} /> */}
      <Route exact path={`${ROUTES.FAQ}`} component={FAQPage} />
        {/*
      <Route exact path={ROUTES.MEMBERSHIP} component={MembershipPage} />
       <Route exact path={ROUTES.MENTOR} component={MentorPage} />
      <Route exact path={ROUTES.MENTEES} component={MenteesPage} />
      <Route exact path={ROUTES.COMPANIES} component={CompaniesPage} />
      <Route exact path={ROUTES.STUDENTS} component={StudentsPage} />
      <Route exact path={ROUTES.OUR_MENTORS} component={OurMentorsPage} /> */}
      <Redirect to={ROUTES.MAIN_PAGE} />
    </Switch>
  </Suspense>
);

export default observer(RoutesConfig);
