import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {isEmpty} from "lodash";

const TextAreaField = ({placeholder, label, context, required, name, rows,disabled}) => {
  const {errors, setValue, register, getValues} = useContext(context);
  const deapFields = name.split('.');
  const formValue = getValues && getValues(name);
  const [inputValue, setInputValue] = useState(getValues && getValues(name));
  let error;
  if (errors && errors[deapFields[0]] && deapFields.length > 1) {
    let deapError = deapFields.reduce((acc, item) => {
      if (acc) {
        return acc[item];
      }
      return null;
    }, errors);
    error = {content: deapError?.message};
  } else {
    error = errors && errors[name] && {content: errors[name].message};
  }
  useEffect(() => {
    if (getValues && setValue) {
      setValue(name, isEmpty(inputValue) ? undefined : inputValue)
    }
  }, [inputValue])
  useEffect(() => {
    if (formValue !== inputValue) {
      setInputValue(getValues(name))
    }
  }, [formValue])
  return (
    <Form.TextArea
      required={required}
      name={name}
      rows={rows}
      spellCheck={false}
      placeholder={placeholder}
      value={inputValue}
      label={label}
      disabled={disabled}
      onInput={(e) => setInputValue(e.target.value)}
      error={error}
      {...register(name)}
    />
  );
};

TextAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  context: PropTypes.oneOfType([PropTypes.object, PropTypes.symbol]).isRequired,
  required: PropTypes.bool,
  rows: PropTypes.number,
};

TextAreaField.defaultProps = {
  placeholder: 'A paragraph text area for this',
  required: false,
  rows: 4,
};

export default observer(TextAreaField);
