import React from 'react';
import {observer} from 'mobx-react-lite';
import Avatar from 'react-avatar';
import {getAvatarUrl, stringToColor, ROUTES} from 'utils';
import {NavLink} from 'react-router-dom';


const NotificationItem = ({fullName, profileImageUrl, text, group, linkId, eventType}) => {
  const groupDefaultRoute = {
    "NEST": {link: `${ROUTES.NEST}/${linkId}`, target: "_self"},
    "CHAT_GROUP": {link: `${ROUTES.CHAT}?groupId=${linkId}`, target: "_self"},
    "PROJECT": {link: `${ROUTES.PROJECT}/${linkId}`, target: "_self"},
    "ACTIVITY": {link: `${ROUTES.ACTIVITY}/${linkId}`, target: "_self"},
    "CONNECTION": {link: `${ROUTES.CHAT}?senderId=${linkId}`, target: "_self"}
  }
  const eventTypeRoutes = {
    PROJECT_TEAM_JOIN_REQUEST_RECEIVED: {
      link: `${ROUTES.PROJECT_OWNER_VIEW}/${linkId}/?tab=expertsInterest`,
      target: "_self"
    },
    PROJECT_TEAM_LEFT: {link: `${ROUTES.PROJECT_OWNER_VIEW}/${linkId}/?tab=team`, target: "_self"},
    PAYMENT_COMPLETED: {
      link: `${ROUTES.PROJECT_OWNER_VIEW}/${linkId}/?tab=dashboard`,
      target: "_self"
    },
    PROJECT_PENDING_APPROVAL: {
      link: `https://admin.${window.location.hostname.replace('www.', '')}`, target: "_blank",
      foreign: true
    },
    ACTIVITY_PENDING_APPROVAL: {
      link: `https://admin.${window.location.hostname.replace('www.', '')}`, target: "_blank",
      foreign: true
    },
    PROJECT_ANSWER_NEW: {
      link: `${ROUTES.PROJECT_OWNER_VIEW}/${linkId}/?tab=forum`,
      target: "_self"
    },
    PROJECT_COMMENT_NEW: {
      link: `${ROUTES.PROJECT_OWNER_VIEW}/${linkId}/?tab=forum`,
      target: "_self"
    },
  }
  const getPath = () => {
    let route = eventTypeRoutes[eventType];
    if (!route) {
      route = groupDefaultRoute[group]
    }
    return route;
  }
  const route = getPath();
  return (
    <NavLink className="notification-nav" to={route.foreign ? {pathname: route.link} : route.link} target={route.target}>
      <div className="avatar">
        <Avatar
          src={getAvatarUrl(profileImageUrl)}
          color={stringToColor(fullName)}
          round
          textSizeRatio={1.75}
          name={fullName}
          maxInitials={2}
          size={32}
          className="letters"
        />
      </div>
      <div className="notification-message">
        <h4>{fullName}</h4>
        <div className="action">{text}</div>
      </div>
    </NavLink>
  );
};

export default observer(NotificationItem);
