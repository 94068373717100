import { toast } from 'react-toastify';

const initStyle = { fontSize: '16px', fontWeight: 'bold' }

export const errorNotification = (message = 'Something went wrong, please try again later', autoClose = 1500) => {
  toast.error(message, { position: 'top-right', autoClose, style: initStyle });
}

export const successNotification = (message = 'Nothing went wrong!', autoClose = 1500) => {
  toast.success(message, { position: 'top-right', autoClose, style: initStyle });
}

const initOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  pauseOnFocusLoss: false,
  style: initStyle
};

export const toastNotification = (message, type = 'success', options = {}) => toast[type](message, { ...initOptions, ...options });
