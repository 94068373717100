import axios from 'axios';
import { rootStore } from 'stores';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = window.location.origin;
const apiAxios = axios.create({
  withCredentials: true,
  baseURL: window.location.origin,
});
export const CancelToken = axios.CancelToken;
apiAxios.interceptors.request.use((config) => {
  if (rootStore.userStore.token) {
    config.headers.authorization = `Bearer ${rootStore.userStore.token}`;
  }
  return config;
});

apiAxios.interceptors.response.use(
  (config) => config,
  async (error) => {
      if (error?.response?.status === 504) {
        rootStore.appStore.apiError = true;
      }
      const originalRequest = error.config;
      const isNeedStatus = error?.response?.status === 403;
      if (
        isNeedStatus &&
        error.config &&
        !error.config.isRetry &&
        error.config.url !== 'nest/token'
      ) {
        originalRequest.isRetry = true;

        try {
          console.log('SUCCESS REPEAT', { ...originalRequest });
          await rootStore.userStore.updateAccessToken();
          return apiAxios.request(originalRequest);
        } catch (e) {
          console.log('ERROR REPEAE', e);
          console.error(e.message);
          throw error;
        }
      }
      throw error;
  }
);

export default apiAxios;
