export const cookiePreferencesConfig = {
  strictlyNecessaryCookies: {
    title: 'Strictly Necessary Cookies',
    description:
      'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the website will not then work. These cookies do not store any personally identifiable information.',
  },
  analyticsCookies: {
    title: 'Analytics Cookies',
    description:
      'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our website. They help us to know which pages are the most and least popular and see how visitors move around the website. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our website and will not be able to monitor its performance.',
  },
  functionalCookies: {
    title: 'Functional Cookies',
    description:
      'These cookies are set by us or by third party service providers we use to implement additional functionalities or to enhance features and website performance, however they are not directly related with the service you requested. Services and functionalities implemented by these cookies support features like automatic filled text box, live web chat platform, non-necessary forms and optional security parameters like a single sign-on (SSO).',
  },
  targetingCookies: {
    title: 'Targeting Cookies',
    description:
      'These cookies may be set through our website by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other websites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.',
  },
};
