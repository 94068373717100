import React from 'react';
import {observer} from 'mobx-react-lite';
import {ROUTES} from 'utils';
import NavbarItem from 'components/blocks/Header/NavbarItem';
import {useUserStore} from 'stores';
import Notification from './Notification';
import Messages from 'components/blocks/Header/Messages';
import NavbarItemPop from 'components/blocks/Header/NavbarItemPop';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Button} from "semantic-ui-react";

const Navbar = () => {
    const {isAuth} = useUserStore();
    const history = useHistory();
    const {pathname} = useLocation();
    const navItems = [
        {
            iconClass: 'icon-compass',
            routePath: ROUTES.MAIN_PAGE,
            title: 'Home',
        },
        {
            iconClass: 'icon-compass',
            routePath: ROUTES.NEST,
            title: 'Community',
        },
        /*  {
            iconClass: 'icon-reputation-outlined',
            routePath: ROUTES.FIND_EXPERT,
            title: 'Our network',
          }, */
        {
            iconClass: 'icon-info',
            routePath: ROUTES.ABOUT_US,
            title: 'About Us',
        },
        /*  {
                          iconClass: 'icon-how-it-works',
                          routePath: ROUTES.HOW_IT_WORKS,
                          title: 'How Bionabu\n works',
                        },*/
    ];
    const mentorshipDropOptions = [
        {
            key: 'For Mentees',
            text: 'For Mentees',
            value: 'For Mentees',
            pathname: ROUTES.MENTEES,
            onClick: () => history.push(ROUTES.MENTEES),
        },
        {
            key: 'For Educators',
            text: 'For Educators',
            value: 'For Educators',
            pathname: ROUTES.MENTOR,
            onClick: () => history.push(ROUTES.MENTOR),
        },
    ];
    const microIntershipOptions = [
        {
            key: 'For Companies',
            text: 'For Companies',
            value: 'For Companies',
            pathname: ROUTES.COMPANIES,
            onClick: () => history.push(ROUTES.COMPANIES),
        },
        {
            key: 'For Students',
            text: 'For Students',
            value: 'For Students',
            pathname: ROUTES.STUDENTS,
            onClick: () => history.push(ROUTES.STUDENTS),
        },
    ];
    return (
        <ul className="height100">
            {navItems.map(({title, iconClass, routePath}) => (
                <li key={title} className="m-hide height100">
                    <div className={`height100 ${routePath === pathname && 'active-link'}`} >
                        <NavbarItem title={title} routePath={routePath} iconClass={iconClass}/>
                    </div>
                </li>
            ))}
            <li className="book-btn">
                <Button fluid as={Link} to={{pathname: 'https://calendly.com/bionabu/book-a-demo'}}
                        target="_blank" className='button-rounded-gradient'>
                    Book a Demo
                </Button>
            </li>
            {isAuth && (
                <>
                    {/*  <li className="height100">
            <NavbarItem
              title="Membership"
              routePath={ROUTES.MEMBERSHIP}
              iconClass="icon-membership"
            />
          </li>
          <li className="height100">
            <Messages title="Messages" routePath={ROUTES.CHAT} iconClass="icon-mail" />
          </li>
          <li className="height100">
            <Notification title="Notifications" iconClass="icon-bell" />
          </li>
            */}
                </>
            )}
            {/*  <li className="m-hide height100">
        <NavbarItemPop
          title={`Mentorship`}
          routePath={ROUTES.MENTOR}
          iconClass="icon-profile"
          dropdownOptions={mentorshipDropOptions}
        />
      </li>
      <li className="m-hide height100">
        <NavbarItemPop
          title="Micro-internship"
          routePath={ROUTES.MICRO_INTERNSHIP}
          iconClass="icon-book"
          dropdownOptions={microIntershipOptions}
        />
      </li> */}
        </ul>
    );
};

export default observer(Navbar);
