import { createContext } from 'react';

export const LoginFormContext = createContext();
export const RegistrationFormContext = createContext();
export const FilterFormContext = createContext();
export const FiltersForMobileFormContext = createContext();
export const PostFormContext = createContext();
export const ActivityFormContext = createContext();
export const ProjectFormContext = createContext();
export const ForgotPasswordFormContext = createContext();
export const ResetPasswordFormContext = createContext();
export const SendMessageFormContext = createContext();
export const ShareHolderContext = createContext();
export const ReplyFormContext = createContext();
export const EditCommentContext = createContext();
export const SubmitProjectContext = createContext();
export const ContactUsContext = createContext();
export const SubmitYourProjectContext = createContext();
export const YourFeedFormContext = createContext();
export const AddActivityFormContext = createContext();
export const GroupChatFormContext = createContext();
export const SendTeamRequestFormContext = createContext();

export const OverviewCardFormContext = createContext();
