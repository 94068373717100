import {makeAutoObservable, runInAction} from 'mobx';
import {over} from 'stompjs';
import SockJS from 'sockjs-client';
import {autorun} from "mobx"
import {errorNotification} from "utils";
import {successNotification} from "utils/toastify";
import {rootStore} from "stores/index";


export default class WebSocketStore {

  sock = null;
  stompClient = null;
  connecting = false;
  connected = false;
  received = true;

  attemptCount = 0;
  refreshTimer = null;
  pingTrigger = false;
  pingWatcher = null;
  constructor(rootStore) {
    makeAutoObservable(this, {rootStore: false});
    this.rootStore = rootStore;
  }

  // Computed

  // Actions

  connect = () => {
    this.connecting = true;
    const checkToken = async () => {
      let token = this.rootStore.userStore.token;
      const response = await this.rootStore.userStore.updateAccessToken()
      if (response) {
        token = response.token
      }
      return token
    }
    checkToken().then((token) => {
      this.sock = new SockJS(`${process.env.REACT_APP_HOST_URL || "https://stg.bionabu.com"}/nest/secured/sock`);
      this.stompClient = over(this.sock);
    //  this.stompClient.debug = null
      this.stompClient.connect(
        {Authorization: `Bearer ${token}`},
        this.onConnected,
        this.onError
      );
    })
  };
  disconnect = () => {
    this.stompClient?.disconnect(this.onDisConnected);
  }
  onConnected = () => {
    this.stompClient.subscribe('/secured/users/queue/client', this.onPrivateMessage);
    this.attemptCount = 0;
    this.connecting = false;
    this.connected = true;
    this.received = true;
    clearInterval(this.refreshTimer)
    this.pingWatcher = setInterval(() => {
        this.ping()
    }, 10000)
  };
  onDisConnected = () => {
    this.connecting = false;
    this.connected = false;
  }
  onError = () => {
    clearInterval(this.pingWatcher)
    if (!this.refreshTimer) {
      this.connecting = false;
      this.connected = false;
      this.refreshTimer = setInterval(() => {
        if (this.attemptCount< 10  && !this.connected) {
          this.connecting = false;
          this.attemptCount = this.attemptCount + 1;
        } else if (this.connected) {
          this.rootStore.appStore.apiError = false;
          clearInterval(this.refreshTimer)
          this.refreshTimer = null;
        } else {
          this.attemptCount=0;
          clearInterval(this.refreshTimer)
          this.refreshTimer=null;
          this.rootStore.appStore.apiError = true;
        }
      }, 10000)
    }
  };
  ping = async () => {
    this.pingTrigger = true;
    this.stompClient.send("/nest/secured/status/ping", {}, "ping");
    setTimeout(() => {
      if (this.pingTrigger) {
        this.disconnect();
        clearInterval(this.pingWatcher)
      }
    }, 1000)
  }
  pong = async () => {
     this.pingTrigger = false;
  }
  onPrivateMessage = (data) => {
    this.received = true;
    data = JSON.parse(data.body);
    if (data.status === "ERROR") {
      errorNotification(data.message);
    }
    switch (data.payload?.type) {
      case 'PONG':
        this.pong()
        break;
      case 'CHAT_MESSAGE':
        this.rootStore.chatStore.onChatMessageReceived(data.payload.payload)
        break;
      case 'CHAT_GROUP_MESSAGE' :
        this.rootStore.chatStore.onGroupChatMessageReceived(data.payload.payload)
        break;
      case 'NOTIFICATION' :
        this.rootStore.notificationStore.onNotificationReceived(data.payload.payload)
        break;
      case 'NOTIFICATIONS_READ' :
        this.rootStore.notificationStore.onNotificationRead(data.payload.payload)
        break;
      default :
    }
  }
  sendPrivateMessage = (message) => {
    if (this.connected) {
      this.received = false;
      setTimeout(() => {
        if (!this.received && this.connected) {
          errorNotification("Lost connection check your network")
          this.disconnect();
        }
      }, 1000)
      this.stompClient.send("/nest/secured/chat", {}, JSON.stringify(message));
    }
  }

  sendGroupMessage = (message) => {
    if (this.connected) {
      this.received = false;
      setTimeout(() => {
        if (!this.received && this.connected) {
          errorNotification("Lost connection check your network")
          this.disconnect();
        }
      }, 1000)
      this.stompClient.send("/nest/secured/chat/group", {}, JSON.stringify(message));
    }
  }
}
