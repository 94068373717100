/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react';
import Spinner from 'components/UI/Spinner';
import { confirmEmail } from 'services';
import { useHistory, useLocation } from 'react-router-dom';
import { tagManagerWrapper } from '../../../utils/helpers';

export const ConfirmEmail = () => {
  const { search } = useLocation();
  const token = search.slice(1);

  const history = useHistory();

  useEffect(() => {
    const sendConfirmToken = async () => {
      const res = await confirmEmail(token);
      if (!res) {
        const errorConstantMessage = 'Something went wrong';
        history.push({ pathname: '/signin', errorConstantMessage });
      } else if (res.status === 200 || res.status === 204) {
        const constantMessage = 'Your email address confirmed';
        tagManagerWrapper({
          dataLayer: {
            event: 'user_registration_done',
          },
        });
        history.push({ pathname: '/signin', constantMessage });
      } else if (res.status === 400) {
        const errorConstantMessage = 'That email address is already in use';
        history.push({ pathname: '/signin', errorConstantMessage });
      }
    };
    sendConfirmToken();
  }, [token]);

  return (
    <div
      style={{
        background: '#FFFFFF',
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        zIndex: '999',
      }}
    >
      <Spinner />
    </div>
  );
};
