import {makeAutoObservable, runInAction} from "mobx";
import * as Api from "services";
import {platformFiltersMap} from "stores/platform.store";

export default class LandingStore {

  projects = [];
  activities = [];
  totalProjects = 0;
  filterParams = {
    [platformFiltersMap.count]: 6,
    [platformFiltersMap.page]: 0,
  };
  isFirstProjectsPage = true;
  isLastProjectsPage = false;
  totalProjectsPages = 0;

  loadLandingData = async () => {
    let projects = null;
    if (this.totalProjectsPages >= this.filterParams.page) {
      if (this.rootStore.userStore.isAuth) {
        projects = Api.loadProjects(this.filterParams);
      } else {
        projects = Api.loadPublicProjects(this.filterParams);

      }
    }
    const result = await projects;
    runInAction(() => {
      if (result) {
        this.projects = result.content;
        this.totalProjectsPages = result.totalPages;
        this.filterParams.page = result.number;
        this.isLastProjectsPage = result.last;
        this.isFirstProjectsPage = result.first;
      } else {
        this.projects = [];
      }
    })
  }

  constructor(rootStore) {
    makeAutoObservable(this, {rootStore: false});
    this.rootStore = rootStore;
  }

  loadNextPage = async () => {
    if (!this.isLastProjectsPage) {
      this.filterParams.page = this.filterParams.page + 1;
      this.loadLandingData();
    }
  };
  loadPreviousPage = async () => {
    if (!this.isFirstProjectsPage) {
      this.filterParams.page = this.filterParams.page - 1;
      this.loadLandingData();
    }
  };
  setFilterParamsCount = async (count) => {
    this.filterParams = {...this.filterParams, count: count}
    await this.loadLandingData();
  }
}
