import {makeAutoObservable, runInAction, toJS} from 'mobx';
import * as Api from 'services';
import {platformFiltersMap} from 'stores/platform.store';
import {ROUTES} from 'utils';
import {ACTIVITY_FIELDS} from "contants/common";

export const formActivityActiveTabMap = {
  main: 'About',
  overview: 'Overview',
  faq: 'FAQ',
};
export const postFiltersMap = {
  count: 'count',
  sortBy: 'sort-by',
  sortOrder: 'sort-order',
  tags: 'tags',
  industry: 'industry',
  area: 'area',
  activityType: 'activityType',
};
export const activityStatusMap = {
  "DRAFT": "Draft",
  "PUBLISHED": "Published",
  "PENDING_APPROVAL": "Pending Approval",
}
export default class ActivityStore {
  loading = false;
  total = 0;
  activities = [];
  activeActivity = null;
  filterParams = {
    [platformFiltersMap.count]: 10,
  };
  formActiveTab = formActivityActiveTabMap.main;

  /*my activities*/
  myActivities = [];
  myActivitiesLoading = false;
  isFirstPageMyActivities = true;
  isLastPageMyActivities = false;
  totalPagesMyActivities = 0;
  myActivitiesFilterParams = {
    "count": 50,
  };
  /*saved activities*/
  savedActivities = [];
  savedActivitiesLoading = false;
  isFirstPageSavedActivities = true;
  isLastPageSavedActivities = false;
  totalPagesSavedActivities = 0;
  savedActivitiesFilterParams = {
    "count": 50,
  };


  constructor(rootStore) {
    makeAutoObservable(this, {rootStore: false});
    this.rootStore = rootStore;
  }

  /*Activities*/

  loadOneActivity = async (id, withView = false) => {
    let result = null;
    if (this.rootStore.userStore.isAuth) {
      result = await Api.loadOneActivity(id, withView);
    } else {
      result = await Api.loadOnePublicActivity(id, withView)
    }
    if (result && result.id) {
      runInAction(() => {
        this.activeActivity = result;
      });
      return true;
    }
    return false;
  };
  loadActivities = async (platformParams = this.filterParams) => {
    this.loading = true;
    platformParams = {...platformParams, ...this.filterParams};
    let response = null;
    if (this.rootStore.userStore.isAuth) {
      response = await Api.loadActivities(platformParams);
    } else {
      response = await Api.loadPublicActivities(platformParams);
    }
    if (response && response.content) {
      runInAction(() => {
        this.activities = response.content;
        this.total = response.totalElements;
        this.filterParams.page = response.number;
        this.isLastPage = response.last;
        this.isFirstPage = response.first;
      });
    }
    this.loading = false;
  };
  loadMoreActivities = async () => {
    if (!this.isLastPage) {
      this.filterParams.page = this.filterParams.page + 1
    }
    const platformParams = {...this.rootStore.platformStore.platformFilter, ...this.filterParams};
    let response = null;
    if (this.rootStore.userStore.isAuth) {
      response = await Api.loadActivities(platformParams);
    } else {
      response = await Api.loadPublicActivities(platformParams);
    }
    if (response && response.content) {
      runInAction(() => {
        this.activities = [...this.activities, ...response.content];
        this.total = response.totalElements;
        this.totalPages = response.totalPages;
        this.isLastPage = response.last;
        this.isFirstPage = response.first;
        this.filterParams.page = response.number;
      });
    }
  }
  loadNextPage = async () => {
    if (!this.isLastPage) {
      this.filterParams.page =  this.filterParams.page +1;
      this.loadActivities(this.rootStore.platformStore.platformFilter);
    }
  };
  loadPreviousPage = async () => {
    if (!this.isFirstPage) {
      this.filterParams.page =  this.filterParams.page - 1;
      this.loadActivities(this.rootStore.platformStore.platformFilter);
    }
  };

  addActivity = async (data) => {
    data = await this.prepareActivityForm(data);
    const result = await Api.addActivity(data);
    if (result && result.id) {
      this.loadActivities();
      return result.id;
    }
  };
  updateActivity = async (data, id) => {
    data = await this.prepareActivityForm(data);
    const response = await Api.updateActivity(data, id);
    if (response && response.status === 200) {
      return response.data.payload.id
    } else {
      return false;
    }
  };
  deleteActivity = async (id) => {
    const res = await Api.deleteActivity(id);
    if (res) {
      runInAction(() => {
        if (this.activeActivity && this.activeActivity.id === id) {
          this.activeActivity = null;
        }
        this.activities = this.activities.filter(item => item.id !==id);
      });
      this.loadMyActivities();
    }
  };
  prepareActivityForm = async (data) => {
    if (data.tags) {
      data.tags = data.tags.map((tag) => ({id: tag}));
    }
    if (data.eventPeriods && data.eventPeriods[0].startDateTime && data.eventPeriods[0].endDateTime) {
      data.eventPeriods = data.eventPeriods.map((period) => {
        const start = new Date(period[ACTIVITY_FIELDS.START_DATE_TIME]);
        const end = new Date(period[ACTIVITY_FIELDS.END_DATE_TIME]);
        const startWithDate= new Date(period[ACTIVITY_FIELDS.EVENT_DATE]);
        const endWithDate= new Date(period[ACTIVITY_FIELDS.EVENT_DATE]);
        startWithDate.setHours(start.getHours())
        startWithDate.setMinutes(start.getMinutes())
        endWithDate.setHours(end.getHours())
        endWithDate.setMinutes(end.getMinutes())
        return {
          startDateTime: startWithDate.toISOString(),
          endDateTime: endWithDate.toISOString(),
        }
      });
    } else {
      delete data.eventPeriods
    }

    if (data.mediaKey?.img) {
      data.mediaKey = data.mediaKey.img;
    } else if (data.mediaKey?.file) {
      const formData = new FormData();
      formData.append('media', data.mediaKey.file);
      const res = await Api.uploadActivityImage(formData);

      if (res.resourceKey) {
        data.mediaKey = res.resourceKey;
      }
    }
    return data;
  };
  /*MyProjects*/
  loadMyActivities = async (expertId = this.rootStore.userStore.user.expertId) => {
    this.myActivitiesLoading = true;
    const result = await Api.loadMyActivities(expertId, this.myActivitiesFilterParams);
    if (result) {
      runInAction(() => {
        this.myActivities = result.content;
        this.isFirstPageMyActivities = result.first;
        this.isLastPageMyActivities = result.last;
        this.totalPagesMyActivities = result.totalPages;
        this.myActivitiesFilterParams.page = result.number;
      });
    }
    this.myActivitiesLoading = false;
  }
  loadNextPageMyActivities = async () => {
    if (!this.isLastPageMyActivities) {
      this.myActivitiesFilterParams.page =   this.myActivitiesFilterParams.page +1 ;
      await this.loadMyActivities();
    }
  };
  loadPreviousPageMyActivities = async () => {
    if (!this.isFirstPageMyActivities) {
      this.myActivitiesFilterParams.page =   this.myActivitiesFilterParams.page - 1;
      await this.loadMyActivities();
    }
  };

  /*SavedProjects*/
  loadSavedActivities = async (expertId = this.rootStore.userStore.user.expertId) => {
    this.savedActivitiesLoading = true;
    const result = await Api.loadSavedActivities(expertId, this.savedActivitiesFilterParams);
    if (result) {
      runInAction(() => {
        this.savedActivities = result.content;
        this.savedActivitiesFilterParams.page = result.number;
        this.isFirstPageSavedActivities = result.first;
        this.isLastPageSavedActivities = result.last;
        this.totalPagesSavedActivities = result.totalPages;
      });
    }
    this.savedActivitiesLoading = false;
  }
  loadNextPageSavedActivities = async () => {
    if (!this.isLastPageSavedActivities) {
      this.savedActivitiesFilterParams.page =this.savedActivitiesFilterParams.page +1;
      await this.loadSavedActivities();
    }
  };
  loadPreviousPageSavedActivities = async () => {
    if (!this.isFirstPageSavedActivities) {
      this.savedActivitiesFilterParams.page = this.savedActivitiesFilterParams.page -1;
      await this.loadSavedActivities();
    }
  };

  saveToMyActivities = async (projectId) => {
    const result = await Api.saveToMyActivities(projectId);
    if (result.status === 204) {
      runInAction(() => {
        this.activities =this.activities.map(item => {
          if (item.id ===projectId) {
            return {...item, savedToFavourites : !item.savedToFavourites};
          } else {
            return item;
          }
        })
      })
    }
  }
  /*activity form*/
  setFormActiveTab = (value) => {
    runInAction(() => {
      this.formActiveTab = value;
    })
  }
  setActiveActivity = (value) => {
    runInAction(() => {
      this.activeActivity = value;
    })
  }
}
