import {makeAutoObservable, runInAction} from "mobx";
import * as Api from "services";

export default class NotificationStore {

  notifications = [];
  notificationsCount = 0;
  messageNotification = [];
  messageNotificationCount = 0;

  archiveNotificationCount = 0;

  constructor(rootStore) {
    makeAutoObservable(this, {rootStore: false});
    this.rootStore = rootStore;
  }


  loadNotifications = async (page, unread) => {
    const result = await Api.loadNotifications(page, unread, ["NEST", "CONNECTION", "CHAT_GROUP", "PROJECT", "ACTIVITY"]);
    if (result) {
      runInAction(() => {
        page === 0
          ? (this.notifications = result.content)
          : (this.notifications = [...this.notifications, ...result.content]);
        this.notificationsCount = result.totalElements;
      });
      return result;
    }
  };
  loadMessageNotifications = async (page, unread) => {
    const result = await Api.loadNotifications(page, unread, ["MESSAGE"]);
    if (result) {
      runInAction(() => {
        page === 0
          ? (this.messageNotification = result.content)
          : (this.messageNotification = [...this.messageNotification, ...result.content]);
        this.messageNotificationCount = result.totalElements;
      });
      return result;
    }
  };
  onNotificationReceived = async (data) => {
    if (data?.group === "MESSAGE") {
      this.loadArchivedNotificationsCount();
      await this.loadMessageNotifications(0, true);
    } else {
      await this.loadNotifications(0, true)
    }
    if (data?.group === "CHAT_GROUP"){
      await this.rootStore.chatStore.loadChat();
    }
      }
  onNotificationRead = async ({groups, ids}) => {
    if (groups.includes("MESSAGE")) {
      this.rootStore.chatStore.loadChat();
      this.loadMessageNotifications(0, true);
    }
    if (groups.includes("NEST")) {
      this.loadNotifications(0, true);
    }
    if (groups.includes("CONNECTION")) {
      this.loadNotifications(0, true);
    }
    if (groups.includes("CHAT_GROUP")) {
      this.loadNotifications(0, true);
    }
    if (groups.includes("PROJECT")) {
      this.loadNotifications(0, true);
    }
    if (groups.includes("ACTIVITY")) {
      this.loadNotifications(0, true);
    }
  }
  readOneNotification = async (id) => {
    const result = await Api.readOneNotification(id);
  };
  readAllNotification = async () => {
    const params = {
      "groups": [
        "NEST", "CONNECTION", "CHAT_GROUP", "PROJECT","ACTIVITY"
      ]
    };
    const result = await Api.readAllNotification(params);
    if (result && result.status >= 200 && result.status < 300) {
      runInAction(() => {
        this.notifications = null;
        this.notificationsCount = 0;
      });
    }
  };
  readAllMessageNotification = async () => {
    const params = {
      "groups": [
        "MESSAGE"
      ]
    };
    const result = await Api.readAllNotification(params);
    if (result && result.status >= 200 && result.status < 300) {
      runInAction(() => {
        this.messageNotification = null;
        this.messageNotificationCount = 0;
      });
    }
  };
  loadArchivedNotificationsCount = async () => {
    const response =  await   Api.loadArchivedNotificationsCount(true,true);
    this.archiveNotificationCount = response;
  }
}
