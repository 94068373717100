import { autorun, makeAutoObservable, runInAction } from 'mobx';
import * as Api from 'services';
import { EXTRA_TIME_SEC, LOCALSTORAGE_KEYS, REFRESH_INTERVAL_SEC } from 'contants/common';
import { errorNotification, setExpiresTimeToLocalStorage } from 'utils';
import { successNotification } from 'utils/toastify';
import { isNull } from 'lodash';
import { rootStore } from 'stores/index';
import { checkAuth } from 'services';

export const logger = (data, string = 'Data') =>
  console.log(string, JSON.parse(JSON.stringify(data)));

window.addEventListener('storage', (event) => {
  if (event.key === LOCALSTORAGE_KEYS.ACTIVE_USER_ID) {
    if (localStorage.getItem(LOCALSTORAGE_KEYS.ACTIVE_USER_ID)) {
      rootStore.userStore.checkAuth();
    } else {
      rootStore.userStore.logout();
      document.location.reload();
    }
  }
});
class UserStore {
  isAuth = false;

  user = {};

  usersRecommendedConnections = [];

  firstSave = true;

  fullProfile = {};

  token = null;

  activeStep = 0;

  isWatched = false;

  watchRef = null;

  disposer = null;

  flagRender = false; // use for re-render avatar component when update avatar image in profile

  notificationSettings = [];

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false, firstSave: false });
    this.rootStore = rootStore;
  }

  registerUser = async (data) => {
    // const token = await Api.registerUser(data);
  };

  uploadAvatar = async (file, id, profileId) => {
    const res = await Api.uploadAvatar(file, id, profileId);
    return res;
  };

  loginUser = async (data) => {
    const loginData = { ...data };
    sessionStorage.setItem(LOCALSTORAGE_KEYS.STAY_LOGGED, true.toString());
    if (data.rememberMe) {
      localStorage.setItem(LOCALSTORAGE_KEYS.STAY_LOGGED, true.toString());
    } else {
      localStorage.removeItem(LOCALSTORAGE_KEYS.STAY_LOGGED);
    }

    const { expert, token } = await Api.loginUser(loginData);

    if (expert && token) {
      this.getNotificationSettings(this.fullProfile.id);
      this.rootStore.connectionStore.loadFriends(expert.expertId);
      setExpiresTimeToLocalStorage();
      localStorage.setItem(LOCALSTORAGE_KEYS.ACTIVE_USER_ID, expert.expertId);
      runInAction(() => {
        this.isAuth = true;
        this.token = token.replace('Bearer ', '');
        this.user = expert;
      });
      if (!this.isWatched) {
        this.watchRefreshToken();
      }
    }
  };

  deleteProfile = async () => {
    const response = await Api.deleteProfile(this.user.expertId, this.fullProfile.id);
    if (response == 200 || response == 204) {
      localStorage.removeItem(LOCALSTORAGE_KEYS.ACTIVE_USER_ID);
      localStorage.removeItem(LOCALSTORAGE_KEYS.STAY_LOGGED);
      sessionStorage.removeItem(LOCALSTORAGE_KEYS.STAY_LOGGED);
      runInAction(() => {
        this.setIsWatched(false);
        this.isAuth = false;
        this.token = null;
      });
      if (this.watchRef) clearInterval(this.watchRef);
      return true;
    }
    return false;
  };

  logout = async () => {
    localStorage.removeItem(LOCALSTORAGE_KEYS.ACTIVE_USER_ID);
    localStorage.removeItem(LOCALSTORAGE_KEYS.STAY_LOGGED);
    sessionStorage.removeItem(LOCALSTORAGE_KEYS.STAY_LOGGED);
    await Api.logout();
    runInAction(() => {
      this.setIsWatched(false);
      this.isAuth = false;
      this.token = null;
      this.user = {};
      this.fullProfile = {};
      this.firstSave = true;
    });
    if (this.watchRef) clearInterval(this.watchRef);
  };

  setUserName = (name) => {
    this.name = name;
  };

  setActiveStep = (step) => {
    this.activeStep = step;
  };

  setFirstStep = (step) => {
    this.activeStep = step;
  };

  updateRefreshToken = async () => {
    setExpiresTimeToLocalStorage();
    const result = await Api.updateRefreshToken();
  };

  updateAccessToken = async () => {
    const result = await Api.checkAuth();
    if (result) this.token = result.token;
    return result;
  };

  setCookieDate = () => {
    this.user.cookiesPolicyAcceptTime = new Date().toISOString();
  };

  setTermsOfServiceDate = () => {
    this.user.termsOfServiceAcceptTime = new Date().toISOString();
  };

  checkAuth = async () => {
    const result = await Api.checkAuth();
    if (result) {
      runInAction(() => {
        this.token = result.token;
        this.isAuth = true;
      });
      const expert = await Api.getExpertProfile();
      runInAction(() => {
        this.user = expert;
      });
      return { redirect: false };
    }
    return { redirect: true };
  };

  setToken = (token) => {
    this.token = token;
  };

  setIsWatched = (value = true) => {
    this.isWatched = value;
  };

  watchRefreshToken = () => {
    this.setIsWatched(true);
    this.watchRef = setInterval(() => {
      const nowTime = new Date().getTime();
      const expires = localStorage.getItem(LOCALSTORAGE_KEYS.EXPIRES);
      const isNeedRefresh =
        expires &&
        Number(expires) - nowTime <= EXTRA_TIME_SEC * 1000 &&
        Number(expires) - nowTime > 0;
      const isExpired = expires && Number(expires) - nowTime <= 0;
      if (isNeedRefresh) {
        this.updateRefreshToken();
      } else if (isExpired) {
        this.setIsWatched(false);
        clearInterval(this.watchRef);
      }
    }, REFRESH_INTERVAL_SEC * 1000);
    return { clear: () => clearInterval(this.watchRef) };
  };

  updateProfile = async (field, value, withNull = false) => {
    runInAction(() => {
      if (field && !isNull(value)) {
        this.fullProfile[field] = value;
      }
      if (field && withNull && isNull(value)) {
        this.fullProfile[field] = value;
      }
      if (field === 'profileImageUrl') {
        this.flagRender = !this.flagRender;
      }
    });
  };

  updateLocalExpert = (field, value, withNull = false) => {
    if (field && !isNull(value)) {
      this.user[field] = value;
    }
    if (field && withNull && isNull(value)) {
      this.user[field] = value;
    }
  };

  getFullProfile = async (id) => {
    const res = await Api.getFullExpertProfile(id);
    // dispose autorun function
    if (this.disposer) {
      this.firstSave = true;
      this.disposer();
    }
    if (res) {
      runInAction(() => {
        this.disposer = autorun(
          () => {
            if (this.user.expertId && this.fullProfile.id) {
              this.saveProfileToBackend();
            }
          },
          { delay: 500 }
        );
        this.fullProfile = res;
      });
    }
  };

  saveProfileToBackend = () => {
    const toSave = JSON.stringify(this.fullProfile);
    if (this.firstSave) {
      this.firstSave = false;
    } else {
      runInAction(() =>
        Api.updateProfile(this.user.expertId, this.fullProfile.id, toSave).then((res) => {
          if (res === 204) {
            successNotification('Profile updated!', 2500);
          } else if (res >= 400) {
            errorNotification();
          }
        })
      );
    }
  };

  changePassword = async (data) => {
    const { currentPassword, newPassword } = data;
    const sendData = { currentPassword, newPassword };
    const result = await Api.changePassword(sendData);
    if (result === 204) {
      successNotification('Password changed!', false);
      return true;
    }
    if (result >= 400) {
      errorNotification('Something went wrong.', false);
      return false;
    }
  };

  resetPassword = async (email) => {
    const result = await Api.resetPassword(email);
    if (result === 204) {
      successNotification('Verification code sent. Check your e-mail address', false);
      return true;
    }
    if (result >= 400) {
      errorNotification('Something went wrong.', false);
      return false;
    }
  };

  checkCode = async (data) => {
    const result = await Api.checkCode(data);
    if (result === 204) {
      successNotification('Your verification code accepted', false);
      return true;
    }
    return false;
  };

  changeForgottenPassword = async (data) => {
    const result = await Api.changeForgottenPassword(data);
    if (result === 204) {
      successNotification('Password changed successfully', false);
      return true;
    }
    if (result >= 400) {
      errorNotification('Something went wrong.', false);
      return false;
    }
  };

  loadUsersRecommendedConnections = async (expertId) => {
    const result = await Api.loadRecommendedConnections(expertId);
    if (result) {
      runInAction(() => {
        this.usersRecommendedConnections = [...result];
      });
    }
  };

  removeAvatar = async (expertId, profileId, profileImageUrl) => {
    const result = await Api.removeAvatar(expertId, profileId, profileImageUrl);
    if (result && result.status === 204) {
      runInAction(() => {
        this.updateProfile('profileImageUrl', null, true);
        this.updateLocalExpert('profileImageUrl', null, true);
      });
    }
  };

  getNotificationSettings = async (profileId) => {
    const res = await Api.getNotificationSettings(profileId);
    if (res) {
      runInAction(() => {
        this.notificationSettings = res.notificationSettings;
      });
    }
  };

  updateNotificationSettings = async (profileId, settingId) => {
    const res = await Api.updateNotificationSettings(profileId, settingId);
    const { payload } = res;
    this.notificationSettings = this.notificationSettings.map((n) => {
      if (n.id === payload.id) {
        n.selected = payload.selected;
      }
      return n;
    });
  };
}

export default UserStore;
