export function capitalizeFirstLetter(str) {
  if (!str || typeof str === 'number') return str;
  return str[0].toUpperCase() + str.slice(1);
}
export const dateFormatter = (date, withTime = true) => {
  const weekDay = ['Sun', 'Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat'];
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const wd = weekDay[date.getDay()];
  const mn = month[date.getMonth()];
  const year = date.getFullYear();
  const day = date.getDate();
  const hour = date.getHours();
  const min = date.getMinutes();
  if(withTime) {
    return `${wd}, ${day} ${mn} ${year}, ${hour}:${min}${min < 10 ? '0' : ''} `;
  } else {
    return `${day} ${mn} ${year} `;
  }

};

export const periodFormatter = (start, end) => {
  const getTime = (date) => {
    return ` ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
  };
  if (end.getMilliseconds() - start.getMilliseconds() > 24 * 60 * 60 * 1000) {
    return `${dateFormatter(start)} ${getTime(start)} ${dateFormatter(end)} ${getTime(end)}`;
  } else if (end.getMilliseconds() - start.getMilliseconds() < 1000) {
    return `${dateFormatter(start)} ${getTime(start)}`;
  } else {
    return `${dateFormatter(start)} ${getTime(start)}-${getTime(end)}`;
  }
};
export const capitalize = (word) => {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}
