import React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, TransitionablePortal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ModalTransition = ({
  show,
  children,
  onClose,
  onOpen,
  className,
  size,
  closeOnDimmerClick,
}) => (
  <TransitionablePortal
    open={show}
    closeOnTriggerClick
    onOpen={onOpen}
    transition={{ animation: 'fade down', duration: 300 }}
  >
    <Modal
      closeOnDimmerClick={closeOnDimmerClick}
      style={{
        maxWidth: 'calc(100vw - 30px)',
      }}
      size={size}
      className={className}
      onClose={onClose}
      open
    >
      {children}
    </Modal>
  </TransitionablePortal>
);

ModalTransition.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  closeOnDimmerClick: PropTypes.bool,
};

ModalTransition.defaultProps = {
  className: '',
  size: '',
  closeOnDimmerClick: true,
};

export default observer(ModalTransition);
