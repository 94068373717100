import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import 'components/blocks/Header/Header.scss';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'utils';
import { useUserStore } from 'stores';

const HiddenMenuItem = ({ onClick, iconClass, routePath, title, active, needAuth }) => {
  const { isAuth } = useUserStore();
  const display = (needAuth && isAuth) || !needAuth;
  return (
    <>
      {display && (
        <NavLink
          className={`hidden-menu-item ${active && 'active-link'}`}
          to={routePath}
          onClick={onClick}
        >
          <div className="item-content">
            <span className={iconClass} />
            <div>{title}</div>
          </div>
          {/* <span className="icon-array-next"/> */}
        </NavLink>
      )}
    </>
  );
};
export default observer(HiddenMenuItem);
