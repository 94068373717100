import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './InputField.scss';
const InputField = ({
  name,
  type,
  placeholder,
  label,
  context,
  required,
  className,
  stepsValues,
  disabled,
}) => {
  const { register, errors, getValues, setValue } = useContext(context);
  const deapFields = name.split('.');
  const formValue = getValues && getValues(name);
  const [inputValue, setInputValue] = useState(
    formValue || (stepsValues && stepsValues[name] ? stepsValues[name] : undefined)
  );
  let error;
  if (errors && errors[deapFields[0]] && deapFields.length > 1) {
    let deapError = deapFields.reduce((acc, item) => {
      if (acc) {
        return acc[item];
      }
      return null;
    }, errors);
    error = { content: deapError?.message };
  } else {
    error = errors && errors[name] && { content: errors[name].message };
  }
  useEffect(() => {
    if (getValues && setValue) {
      setValue(name, inputValue === '' ? undefined : inputValue);
    }
  }, [inputValue]);
  useEffect(() => {
    if (formValue !== inputValue) {
      setInputValue(getValues(name));
    }
  }, [formValue]);
  return (
    <Form.Input
      className={className}
      required={required}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      name={name}
      type={type}
      value={inputValue}
      onInput={(e) => setInputValue(e.target.value)}
      error={error}
      {...register(name)}
    />
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  context: PropTypes.oneOfType([PropTypes.object, PropTypes.symbol]).isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
};

InputField.defaultProps = {
  placeholder: 'This is a placeholder',
  type: 'text',
  required: false,
  className: '',
};

export default observer(InputField);
