import {errorNotification, errorPromiseCather, toggleLoader} from "utils";
import apiAxios from "axiosConfig";
import qs from "qs";

export const loadPaymentsHistory = async (params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        // .get(postsURL, {
        .get(`/nest/api/orders/history`, {
          params,
          paramsSerializer: (p) => qs.stringify(p, {arrayFormat: 'comma', encode:  true}),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const generatePayPalRedirectLink = async (orderId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/payments/orders/${orderId}/checkout`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  }
  catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
}
export const cancelOrder = async (orderId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/orders/${orderId}/cancel`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  }
  catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
}

