import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';
import {
  useAppStore,
  useChatStore,
  useConnectionStore,
  useNotificationStore,
  useUserStore
} from 'stores';
import {getFullName} from 'utils';
import {debounce} from 'lodash';
import NotificationItem from './NotificationItem';

const Notification = ({iconClass, title}) => {
  const {getFriend} = useConnectionStore();
  const {setActiveContact, loadChat} = useChatStore();
  const {
    notifications,
    notificationsCount,
    loadNotifications,
    readOneNotification,
    readAllNotification,
  } = useNotificationStore();
  const {user} = useUserStore();
  const [currentPage, setCurrentPage] = useState(0);
  const [paginatorDisabled, setPaginatorDisabled] = useState(false);
  const onScrollList = debounce(async (event) => {
    if (paginatorDisabled) return;
    if (
      Math.ceil(event.target.clientHeight + event.target.scrollTop) >=
      event.target.scrollHeight - 50
    ) {
      const result = await loadNotifications(currentPage + 1, true);
      setCurrentPage(currentPage + 1);
      if (result && result.totalPages - 1 <= currentPage) {
        setPaginatorDisabled(true);
      }
    }
  }, 500);

  const readNotification = async (id) => {
    readOneNotification(id);
    loadNotifications(0, true);
    setCurrentPage(0);
    setPaginatorDisabled(false);
  };

  useEffect(() => {
    loadNotifications(0, true);
  }, [loadNotifications]);

  return (
    <Dropdown
      fluid
      className="height100 notification-dropdown-icon"
      labeled
      icon={false}
      text={() => (
        <div className="notification-link" href="#">
          <div className="notification-wrapper">
            <span className={iconClass}/>
            {notificationsCount > 0 &&
            <div className="counter">{notificationsCount < 100 ? notificationsCount : 99}</div>}

          </div>
        </div>
      )}
    >
      <Dropdown.Menu direction="left" className="notification-dropdown" onScroll={onScrollList}>
        {notifications && notifications.length ? (
          <>
            <Dropdown.Item
              className="notification-item-read-all"
              onClick={() => {
                readAllNotification();
              }}
            >
              Clear All
            </Dropdown.Item>
            {notifications.map((item) => (
              <Dropdown.Item
                key={item.id}
                className="notification-item"
                onClick={() => {
                  readNotification(item.id);
                }}
              >
                <NotificationItem
                  fullName={getFullName(item.sender.firstName, item.sender.lastName)}
                  profileImageUrl={item.sender.profileImageUrl}
                  text={item.text}
                  group={item.group}
                  eventType={item.eventType}
                  linkId={item.linkId}
                />
              </Dropdown.Item>
            ))}
          </>
        ) : (
          <Dropdown.Item>No notifications</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

Notification.propTypes = {
  iconClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default observer(Notification);
