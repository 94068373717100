import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal } from 'semantic-ui-react';
import { useUIStore } from 'stores';
import CloseButton from 'components/UI/CloseButton/CloseButton';
import { ROUTES } from 'utils';
import { useHistory } from 'react-router-dom';

const NotAuthorizedModal = () => {
  const { showNotAuthorizedModal, closeNotAuthorizedModal } = useUIStore();
  const history = useHistory();

  const signUpClickHandler = () => {
    closeNotAuthorizedModal();
    history.push(ROUTES.SIGN_UP);
  };

  const signInClickHandler = () => {
    closeNotAuthorizedModal();
    history.push(ROUTES.SIGN_IN);
  };

  return (
    <Modal onClose={closeNotAuthorizedModal} open={showNotAuthorizedModal} dimmer={'blurring'}>
      <CloseButton onClose={closeNotAuthorizedModal} />
      <Modal.Header>Get Access</Modal.Header>
      <Modal.Content>You need to Sign in or Join now to see more</Modal.Content>
      <Modal.Actions>
        <Button basic onClick={signInClickHandler}>
          Sign In
        </Button>
        <Button onClick={signUpClickHandler}>Join now</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default observer(NotAuthorizedModal);
