import {errorNotification, errorPromiseCather, toggleLoader} from "utils";
import apiAxios from "axiosConfig";

export const loadFriends = async (expertId, page = 0, count = 25, q,permissions) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`nest/api/experts/${expertId}/friends?page=${page}&count=${count}${q ? `&q=${q}` : ""}${permissions ? `&permissions=${permissions}` : ""}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const loadRecommendedConnections = async (expertId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`nest/api/experts/${expertId}/friends/recommended`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const getFriend = async (expertId, friendId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`nest/api/experts/${expertId}/friends/${friendId}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    console.error(error);
  }
};

export const acceptFriendRequest = async (expertId, friendId, data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .put(`nest/api/experts/${expertId}/friends/${friendId}`, data)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const sendFriendRequest = async (expertId, data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`nest/api/experts/${expertId}/friends`, data)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const deleteFriend = async (expertId, friendId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .delete(`nest/api/experts/${expertId}/friends/${friendId}`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
