import React from 'react';
import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';

import PlatformStore from 'stores/platform.store';
import PostStore from 'stores/post.store';
import ActivityStore from 'stores/activity.store';
import ProjectStore from 'stores/project.store';
import WebSocketStore from 'stores/websocket.store';
import { autorun } from 'mobx';
import ChatStore from 'stores/chat.store';
import NotificationStore from 'stores/notification.store';
import ConnectionStore from 'stores/connection.store';
import LandingStore from 'stores/landing.store';
import PaymentsStore from 'stores/payments.store';
import ExpertsStore from './experts.store';
import OptionsStore from './options.store';
import AppStore from './app.store';
import UIStore from './ui.store';
import UserStore from './user.store';

export const history = createBrowserHistory();

export const routingStore = new RouterStore();

export const browserHistory = syncHistoryWithStore(history, routingStore);

class RootStore {
  constructor() {
    this.router = routingStore;
    this.appStore = new AppStore(this);
    this.userStore = new UserStore(this);
    this.expertsStore = new ExpertsStore(this);
    this.uiStore = new UIStore(this);
    this.optionsStore = new OptionsStore(this);
    this.websocketStore = new WebSocketStore(this);
    this.platformStore = new PlatformStore(this);
    this.postStore = new PostStore(this);
    this.activityStore = new ActivityStore(this);
    this.projectStore = new ProjectStore(this);
    this.chatStore = new ChatStore(this);
    this.notificationStore = new NotificationStore(this);
    this.connectionStore = new ConnectionStore(this);
    this.landingStore = new LandingStore(this);
    this.paymentsStore = new PaymentsStore(this);
  }
}

export const rootStore = new RootStore();
export const StoresContext = React.createContext(rootStore);

export const useStores = () => React.useContext(StoresContext);

export const useUserStore = () => {
  const { userStore } = useStores();
  return userStore;
};

export const useAppStore = () => {
  const { appStore } = useStores();
  return appStore;
};

export const useUIStore = () => {
  const { uiStore } = useStores();
  return uiStore;
};

export const useOptionsStore = () => {
  const { optionsStore } = useStores();
  return optionsStore;
};

export const useExpertsStore = () => {
  const { expertsStore } = useStores();
  return expertsStore;
};

export const usePlatformStore = () => {
  const { platformStore } = useStores();
  return platformStore;
};
export const usePostStore = () => {
  const { postStore } = useStores();
  return postStore;
};
export const useActivityStore = () => {
  const { activityStore } = useStores();
  return activityStore;
};
export const useProjectStore = () => {
  const { projectStore } = useStores();
  return projectStore;
};
export const useWebSocketStore = () => {
  const { websocketStore } = useStores();
  return websocketStore;
};
export const useChatStore = () => {
  const { chatStore } = useStores();
  return chatStore;
};
export const useNotificationStore = () => {
  const { notificationStore } = useStores();
  return notificationStore;
};
export const useConnectionStore = () => {
  const { connectionStore } = useStores();
  return connectionStore;
};
export const useLandingStore = () => {
  const { landingStore } = useStores();
  return landingStore;
};

export const usePaymentsStore = () => {
  const { paymentsStore } = useStores();
  return paymentsStore;
};

autorun(() => {
  if (
    rootStore.userStore.token &&
    !rootStore.websocketStore?.connecting &&
    !rootStore.websocketStore?.connected
  ) {
    rootStore.websocketStore?.connect();
  } else if (!rootStore.userStore.token) {
    rootStore.websocketStore?.disconnect();
  }
});
