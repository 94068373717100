import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { getAvatarUrl, stringToColor } from 'utils';
import AvatarComponent from 'react-avatar';
import { useUserStore } from 'stores';
import { getAvatar, getAvatarIconUrl } from 'utils/helpers';
import { isSafari, isMobileSafari } from 'react-device-detect';
import { Image, Popup } from 'semantic-ui-react';

const Avatar = ({ url, name, size, avatarIcon, withPopUp = true }) => {
  const [key, setKey] = useState(Math.random());
  const { flagRender } = useUserStore();
  const isApple = isSafari || isMobileSafari;
  const [src, setSrc] = useState(isApple ? null : getAvatarUrl(url));
  const [load, setLoad] = useState(isApple);
  const [isPopup, setIsPopup] = useState(false);
  useEffect(() => {
    setKey(Math.random());
    if (isApple) {
      if (url) {
        getAvatar(url)
          .then((r) => {
            if (r) {
              const source = Buffer.from(r.data, 'binary').toString('base64');
              setSrc(`data:${r.headers['content-type']};base64,${source}`);
            }
          })
          .finally(() => setLoad(false));
      } else {
        setLoad(false);
        setSrc(getAvatarUrl(url));
      }
    } else {
      setSrc(getAvatarUrl(url));
    }
  }, [flagRender]);
  useEffect(() => {
    setSrc(isApple ? null : getAvatarUrl(url));
  }, [url]);
  if (load) return null;

  return (
    <div className="avatar-wrapper">
      <AvatarComponent
        key={key}
        src={src}
        color={stringToColor(name)}
        round
        className="letters"
        textSizeRatio={1.75}
        maxInitials={2}
        size={size}
        name={name}
      />
      {avatarIcon ? (
        <Image
          className="flag-icon"
          src={getAvatarIconUrl(avatarIcon.key)}
          onMouseEnter={() => setIsPopup(true)}
          onMouseLeave={() => setIsPopup(false)}
        />
      ) : null}
      {isPopup && withPopUp ? (
        <div className="flag-icon-popup">{avatarIcon.description}</div>
      ) : null}
    </div>
  );
};

export default observer(Avatar);
