import React from 'react';
import { observer } from 'mobx-react-lite';
import { Loader } from 'semantic-ui-react';
import styles from 'components/UI/Spinner/Spinner.module.scss';

const Spinner = ({className}) => {
  return (
    <div className={`${styles.loaderWrapper} ${className}`}>
      <Loader active size="large" />
    </div>
  );
};

export default observer(Spinner);
