import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { isArray, isEmpty } from 'lodash';

export const platformFiltersMap = {
  count: 'count',
  page: 'page',
  sortBy: 'sort-by',
  sortOrder: 'sort-order',
  tags: 'tags',
  industry: 'industry',
  area: 'area',
  activityType: 'activityType',
  view : "view"
};

export default class PlatformStore {
  loading = true;

  platformFilter = {
    [platformFiltersMap.sortOrder]: 'DESC',
    [platformFiltersMap.view] : "Projects",
  };
  modalPlatformFilter = this.platformFilter;

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }
  /*Computed */
  get getTags() {
    const keys = Object.keys(this.platformFilter);
    let tagsArray = [];

    keys.forEach((key) => {
      const isArray = Array.isArray(this.platformFilter[key]);
      if (isArray) {
        tagsArray = tagsArray.concat(
          this.platformFilter[key]
            .filter(() => this.rootStore.optionsStore.optionPlatformMap.get(key))
            .map((paramId) => {
              const item = this.rootStore.optionsStore.optionPlatformMap
                .get(key)
                .find((elem) => elem.id === paramId);
              if (item) {
                return {
                  ...item,
                  key,
                };
              }
              return undefined;
            })
        );
      }
    });
    return tagsArray;
  }
  get filterStatus() {
    const keys = Object.keys(this.platformFilter);
    return keys.some((key) => this.rootStore.optionsStore.optionPlatformMap.get(key));
  }
  get contentAmount() {
    return (
      this.rootStore.postStore.total +
      this.rootStore.activityStore.total +
      this.rootStore.projectStore.total
    );
  }
  /*Actions */
  setFiltersParams = (value, field) => {
    if (field) {
      this.platformFilter[field] = value;
      delete this.rootStore.activityStore.filterParams.page
      delete this.rootStore.projectStore.filterParams.page
    }
    if (isEmpty(this.platformFilter[field])) {
      delete this.platformFilter[field];
    }
    this.updateData();
    this.setModalFilterParams(value, field);
  };

  setModalFilterParams = (value, field) => {
    if (field) {
      this.modalPlatformFilter[field] = value;
      delete this.modalPlatformFilter.page;
    }
  };
  resetFilterFieldParams = (field) => {
    if (field && !Array.isArray(field)) {
      delete this.modalPlatformFilter[field];
      delete this.platformFilter[field];
    }
    if (field && Array.isArray(field)) {
      field.forEach((f) => {
        delete this.modalPlatformFilter[f];
        delete this.platformFilter[f];
      });
    }
    this.updateData();
  };
  resetFiltersParams = () => {
    this.platformFilter = {
      [platformFiltersMap.sortOrder]: 'DESC',
      [platformFiltersMap.view] : "Projects",
    };
    this.modalPlatformFilter = {
      [platformFiltersMap.sortOrder]: 'DESC',
      [platformFiltersMap.view] : "Projects",
    };
    runInAction(() => {
      this.rootStore.postStore.filterParams = {
        [platformFiltersMap.count]: 10,
      }
      this.rootStore.activityStore.filterParams ={
        [platformFiltersMap.count]: 10,
      };
      this.rootStore.projectStore.filterParams ={
        [platformFiltersMap.count]: 30,
      };
    })
    this.updateData();
  };
  applyModalFilters = async () => {
    this.platformFilter = { ...this.modalPlatformFilter };
    this.updateData();
  };
  resetTag = (tag) => {
    const { id, key } = tag;
    const deleteTag = (param) => {
      const isArray = Array.isArray(param[key]);
      if (isArray) {
        param[key] = param[key].filter((item) => item !== id);
        if (param[key].length < 1) {
          delete param[key];
        }
      }
    };

    deleteTag(this.platformFilter);
    deleteTag(this.modalPlatformFilter);
    this.updateData();
  };

  updateData = async () => {
    this.loading = true;
    switch (this.platformFilter[platformFiltersMap.view]){
      case "Projects" :
        await this.rootStore.projectStore.loadProjects(this.platformFilter);
        break;
      case "Activity" :
        await this.rootStore.activityStore.loadActivities(this.platformFilter);
        break;
      case "Posts" :
        await this.rootStore.postStore.loadPosts(this.platformFilter);
        break;
      default :
        const posts = this.rootStore.postStore.loadPosts(this.platformFilter);
        const activities = this.rootStore.activityStore.loadActivities(this.platformFilter);
        const projects = this.rootStore.projectStore.loadProjects(this.platformFilter);
        Promise.all([posts, activities, projects]).then(() => {
        });
    }
    this.loading = false;
  };
}
