import { rootStore } from 'stores';
import axios from 'axios';
import { LOCALSTORAGE_KEYS, REFRESH_TOKEN_LIFE_TIME_SEC } from 'contants/common';
import ReactHtmlParser from 'react-html-parser';
import { dateFormatter } from 'utils/formaters';
import TagManager from 'react-gtm-module';

export const toggleLoader = async (callback) => {
  rootStore.uiStore.increaseCounterRequest();
  const result = await callback();
  rootStore.uiStore.decreaseCounterRequest();
  return result;
};

export const errorPromiseCather = (error) => {
  if (axios.isCancel(error)) return error;
  console.error(error);
  if (error?.response?.status === 403) return error;
  let message =
    error?.response?.message ||
    error?.response?.data?.message ||
    error?.response?.error ||
    error?.response?.data?.error;
  if (error?.response?.status === 401 || message === 'Unauthorized') {
    message = 'Incorrect email or password';
  }
  rootStore.uiStore.decreaseCounterRequest();
  throw new Error(message);
};

export const setExpiresTimeToLocalStorage = () => {
  // * 1000 set in milliseconds
  const expiresTime = new Date().getTime() + REFRESH_TOKEN_LIFE_TIME_SEC * 1000;
  localStorage.setItem(LOCALSTORAGE_KEYS.EXPIRES, expiresTime.toString());
};

export const prepareDataToSelect = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }
};
export const prepareConnectionToSelect = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => ({
      key: item.expertData.expertId,
      value: item.expertData.expertId,
      text: `${item.expertData.firstName} ${item.expertData.lastName}`,
      image: item.expertData.profileImageUrl ? getAvatarUrl(item.expertData.profileImageUrl) : null,
    }));
  }
};
export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const getAvatarUrl = (uuid) => {
  if (!uuid) return '';
  return `${window.origin}/nest/api/public/experts/avatar/${uuid}`;
};
export const getAvatarIconUrl = (uuid) => {
  if (!uuid) return '';
  return `${window.origin}/nest/api/public/experts/avatar/icon/${uuid}`;
};
export const getActivityIcon = (uuid) => {
  if (!uuid) return '';
  return `${window.origin}/nest/api/public/activities/media/download/${uuid}`;
};
export const getProjectIcon = (uuid) => {
  if (!uuid) return '';
  return `${window.origin}/nest/api/public/projects/media/download/${uuid}`;
};
export const getGroupChatIcon = (uuid) => {
  if (!uuid) return '';
  return `${window.origin}/nest/api/public/chat/groups/avatars/download/${uuid}`;
};
export const getAvatar = (uuid) => {
  try {
    return axios.get(`${window.origin}/nest/api/public/experts/avatar/${uuid}`, {
      responseType: 'arraybuffer',
    });
  } catch (e) {
    console.error(e.message);
    return '';
  }
};

export const getFullName = (a, b) => `${a} ${b}`;

export const getQueryParam = (param) => {
  const rx = new RegExp(`[?&]${param}=([^&]+).*$`);
  const result = window.location.search.match(rx);
  return result === null ? '' : result[1];
};

const replacerTerm = (query, body) => {
  const regExp = new RegExp(query.replace('.', '\\.'), 'gi');
  return body.replace(regExp, (match) => `<mark class='highlighted'>${match}</mark>`);
};

export const highlightTerm = (term, body) => {
  if (typeof body !== 'string') {
    return body;
  }

  if (term) {
    return ReactHtmlParser(replacerTerm(term, body));
  }

  return ReactHtmlParser(body);
};

export const getInitialValuesProjectForm = (PROJECT_FIELDS, postTags, activeProject) => {
  const initialState = {};
  Object.values(PROJECT_FIELDS).forEach((key) => {
    switch (key) {
      case PROJECT_FIELDS.BUDGET:
        if (activeProject[key]) {
          initialState[`${key}.${PROJECT_FIELDS.CURRENCY}`] =
            activeProject[key][PROJECT_FIELDS.CURRENCY];
          initialState[`${key}.${PROJECT_FIELDS.AMOUNT}`] =
            activeProject[key][PROJECT_FIELDS.AMOUNT].toString();
        } else {
          initialState[`${key}.${PROJECT_FIELDS.CURRENCY}`] = '$';
        }
        break;
      case PROJECT_FIELDS.FAQ:
        const value = [];
        if (activeProject[key]) {
          activeProject[key].forEach((item, index) => {
            initialState[`${key}.${index}.${PROJECT_FIELDS.QUESTION}`] =
              item[PROJECT_FIELDS.QUESTION];
            initialState[`${key}.${index}.${PROJECT_FIELDS.ANSWER}`] = item[PROJECT_FIELDS.ANSWER];
            value.push(item);
          });
        }
        initialState[key] = value;
        break;
      case PROJECT_FIELDS.TAB_SETTINGS:
        const faqView = activeProject[key].find((item) => item.tabName === 'FAQ');
        initialState[PROJECT_FIELDS.FAQ_VIEW] = faqView.visibilityType;
        initialState[key] = activeProject[key];
        break;
      default:
        if (
          !(
            key === PROJECT_FIELDS.START_DATE_TIME ||
            key === PROJECT_FIELDS.END_DATE_TIME ||
            key === PROJECT_FIELDS.AMOUNT ||
            key === PROJECT_FIELDS.QUESTION ||
            key === PROJECT_FIELDS.ANSWER ||
            key === PROJECT_FIELDS.DESCRIPTION ||
            key === PROJECT_FIELDS.CURRENCY ||
            key === PROJECT_FIELDS.FAQ_VIEW ||
            key === PROJECT_FIELDS.TICKET_INFO ||
            key === PROJECT_FIELDS.TICKET_SYMBOL ||
            key === PROJECT_FIELDS.TICKET_PRICE ||
            key === PROJECT_FIELDS.TICKET_TOTAL ||
            key === PROJECT_FIELDS.TICKET_FREE ||
            key === PROJECT_FIELDS.CO_OWNERS
          )
        ) {
          initialState[key] = activeProject[key];
        }
        break;
    }
  });
  return initialState;
};
export const getInitialValuesActivityForm = (ACTIVITY_FIELDS, postTags, activeActivity) => {
  const initialState = {};
  Object.values(ACTIVITY_FIELDS).forEach((key) => {
    switch (key) {
      case ACTIVITY_FIELDS.EVENT_PERIODS:
        const value = [];
        if (activeActivity[key].length > 0) {
          activeActivity[key].forEach((item, index) => {
            initialState[`${key}.${index}.${ACTIVITY_FIELDS.EVENT_DATE}`] = new Date(
              item[ACTIVITY_FIELDS.START_DATE_TIME]
            );
            initialState[`${key}.${index}.${ACTIVITY_FIELDS.START_DATE_TIME}`] = new Date(
              item[ACTIVITY_FIELDS.START_DATE_TIME]
            );
            initialState[`${key}.${index}.${ACTIVITY_FIELDS.END_DATE_TIME}`] = new Date(
              item[ACTIVITY_FIELDS.END_DATE_TIME]
            );
            item = {
              [ACTIVITY_FIELDS.START_DATE_TIME]: new Date(item[ACTIVITY_FIELDS.START_DATE_TIME]),
              [ACTIVITY_FIELDS.END_DATE_TIME]: new Date(item[ACTIVITY_FIELDS.END_DATE_TIME]),
            };
            value.push(item);
          });
        } else {
          value.push({
            [ACTIVITY_FIELDS.EVENT_DATE]: undefined,
            [ACTIVITY_FIELDS.START_DATE_TIME]: undefined,
            [ACTIVITY_FIELDS.END_DATE_TIME]: undefined,
          });
        }
        initialState[key] = value;
        break;
      default:
        initialState[key] = activeActivity[key];
        break;
    }
  });
  return initialState;
};

export const getActivityCurrentPeriod = (activeActivity) => {
  if (!activeActivity || !activeActivity.eventPeriods) return;
  const periods = [...activeActivity.eventPeriods];
  return periods
    .sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime))
    .find((item) => new Date(item.startDateTime) > new Date());
};
export const getActivityEventPeriod = (activeActivity) => {
  const currentPeriod = getActivityCurrentPeriod(activeActivity);
  if (currentPeriod) {
    const start = new Date(currentPeriod.startDateTime);
    const end = new Date(currentPeriod.endDateTime);
    if (
      start.getFullYear() === end.getFullYear() &&
      start.getMonth() === end.getMonth() &&
      start.getDate() === end.getDate()
    ) {
      return `${dateFormatter(start)}- ${end.getHours()}:${end.getMinutes()}${
        end.getMinutes() < 10 ? '0' : ''
      } GMT${getGTM()}`;
    }
    return `${dateFormatter(start)} - ${dateFormatter(end)} GMT${getGTM()}`;
  }
  return 'Ended';
};
export const formActivityCounterData = (currentPeriod) => {
  const now = new Date();
  const start = new Date(currentPeriod.startDateTime);
  const diff = Math.abs(start - now);
  const d = Math.floor(diff / 1000 / 60 / 60 / 24);
  const h = Math.floor((diff - d * 24 * 60 * 60 * 1000) / 1000 / 60 / 60);
  let min = Math.floor((diff - d * 24 * 60 * 60 * 1000 - h * 60 * 60 * 1000) / 60 / 1000);
  let sec = Math.floor(
    (diff - d * 24 * 60 * 60 * 1000 - h * 60 * 60 * 1000 - min * 60 * 1000) / 1000
  );
  if (min < 10) {
    min = `0${min}`;
  }
  if (sec < 10) {
    sec = `0${sec}`;
  }
  return ` ${d} days ${h}:${min}:${sec}`;
};

export const getGTM = () => {
  const gtmOffset = (new Date().getTimezoneOffset() / 60) * -1;
  return `${gtmOffset > 0 ? `+${gtmOffset}` : gtmOffset}`;
};
export const calcDaysLeft = (date) => {
  const now = new Date();

  if (!date) {
    return -2;
  }
  date = new Date(date);

  if (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    return -3;
  }
  if (date < now) {
    return -1;
  }
  return Math.ceil((date - now) / 1000 / 60 / 60 / 24) - 1;
};

export const getDaysLeftProject = (project) => {
  let date;
  if (project.subcategory === 'MENTORSHIP') {
    date = project?.activePeriod?.startDateTime;
  } else {
    date = project?.activePeriod?.endDateTime;
  }

  const daysLeft = calcDaysLeft(date);
  switch (daysLeft) {
    case -3:
      return 'Today';
    case -1:
      return '';
    case -2:
      return '';
    default:
      return `${daysLeft}`;
  }
};
export const getDaysLeftProjectCard = (project) => {
  let date;
  if (project.subcategory === 'MENTORSHIP') {
    date = project?.activePeriod?.startDateTime;
  } else {
    date = project?.activePeriod?.endDateTime;
  }
  const daysLeft = calcDaysLeft(date);
  switch (daysLeft) {
    case 1:
      return `${daysLeft} day`;
    case -3:
      return `Today`;
    case -1:
      return 'Completed';
    case -2:
      return 'Active';
    default:
      return `${daysLeft} days`;
  }
};
export const getDaysLeftActivity = (activeActivity) => {
  const daysLeft = calcDaysLeft(getActivityCurrentPeriod(activeActivity)?.startDateTime);
  switch (daysLeft) {
    case -3:
      return `Today`;
    case -1:
      return 'Ended';
    case -2:
      return 'Ended';
    default:
      if (daysLeft !== 0 && !daysLeft) {
        return 'Ended';
      }
      return `${daysLeft}`;
  }
};
export const getDaysLeftActivityCard = (activity) => {
  const daysLeft = calcDaysLeft(getActivityCurrentPeriod(activity)?.startDateTime);
  switch (daysLeft) {
    case 1:
      return `${daysLeft} day`;
    case -3:
      return `Today`;
    case -1:
      return 'Ended';
    case -2:
      return 'Ended';
    default:
      if (daysLeft !== 0 && !daysLeft) {
        return 'Ended';
      }
      return `${daysLeft} days`;
  }
};
export const checkProjectAccess = (accessLevel, visibilityType) => {
  if (visibilityType === 'PUBLIC') {
    return true;
  }
  if (
    visibilityType === 'TEAM' &&
    (accessLevel === 'TEAM' || accessLevel === 'CREATOR' || accessLevel === 'CO_OWNER')
  ) {
    return true;
  }
  if (visibilityType === 'CREATOR' && accessLevel === 'CREATOR') {
    return true;
  }
  if (visibilityType === 'CO_OWNER' && (accessLevel === 'CREATOR' || accessLevel === 'CO_OWNER')) {
    return true;
  }
  return false;
};
export const setAvailableProjectSubCategories = (user, projectSubtypes) => {
  const excludedTypes = [];
  if (!user.permissions.includes('MENTORSHIP_PROJECT_CREATE')) {
    excludedTypes.push('MENTORSHIP');
  }
  return projectSubtypes.filter((item) => {
    if (excludedTypes.includes(item)) {
      return false;
    }
    return true;
  });
};

// todo: alternative - use saveAs from file-saver lib
export const downloadFileByLink = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const tagManagerWrapper = (tagManagerArgs) => {
  const values = localStorage.getItem(LOCALSTORAGE_KEYS.COOKIE_PREFERENCES);

  let isActiveAnalytics = false;

  if (values) {
    const parsedValues = JSON.parse(values);
    isActiveAnalytics = Boolean(parsedValues?.analyticsCookie);
  }

  if (isActiveAnalytics) {
    TagManager.dataLayer(tagManagerArgs);
  }
};

export const initializeGtm = () => {
  const values = localStorage.getItem(LOCALSTORAGE_KEYS.COOKIE_PREFERENCES);

  let isActiveAnalytics = false;

  if (values) {
    const parsedValues = JSON.parse(values);
    isActiveAnalytics = Boolean(parsedValues?.analyticsCookie);
  }

  if (isActiveAnalytics && process.env.REACT_APP_GA_TRACKING_CODE) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GA_TRACKING_CODE,
    };
    TagManager.initialize(tagManagerArgs);
  }
};
