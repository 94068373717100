import { errorNotification, errorPromiseCather, toggleLoader } from 'utils';
import apiAxios from 'axiosConfig';

export const loadOptions = async () => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get('/nest/api/info/data')
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (e) {
    errorNotification(e.message);
    console.error(e.response);
  }
  return false;
};

export const loadTagData = async () => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get('/nest/api/info/data')
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const loadPopularTagData = async () => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get('/nest/api/info/tags/popular')
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
