import {errorNotification, errorPromiseCather, toggleLoader} from 'utils';
import apiAxios from 'axiosConfig';
import qs from 'qs';

export const loadCollaborations = async (expertId, params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        // .get(postsURL, {
        .get(`/nest/api/experts/${expertId}/projects/collaborations`, {
          params,
          paramsSerializer: (p) => qs.stringify(p, {arrayFormat: 'comma', encode: true}),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const loadMyProjects = async (expertId, params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        // .get(postsURL, {
        .get(`/nest/api/experts/${expertId}/projects`, {
          params,
          paramsSerializer: (p) => qs.stringify(p, {arrayFormat: 'comma', encode:  true}),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const loadMyArchivedProjects = async (expertId, params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        // .get(postsURL, {
        .get(`/nest/api/experts/${expertId}/projects/archived`, {
          params,
          paramsSerializer: (p) => qs.stringify(p, {arrayFormat: 'comma', encode:  true}),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const loadSavedProjects = async (expertId, params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        // .get(postsURL, {
        .get(`/nest/api/experts/${expertId}/projects/saved`, {
          params,
          paramsSerializer: (p) => qs.stringify(p, {arrayFormat: 'comma', encode:  true}),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
}
export const saveToMyProjects = async (projectId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .patch(`/nest/api/projects/${projectId}/saved/toggle`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const toggleArchived = async (projectId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .patch(`/nest/api/projects/${projectId}/archiving/toggle`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const loadOneProject = async (id,withView) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/projects/${id}?countViews=${withView}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification('Project loading error');
    console.error(error);
  }
};

export const loadProjects = async (params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        // .get(postsURL, {
        .get('/nest/api/projects', {
          params,
          paramsSerializer: (p) => qs.stringify(p, {arrayFormat: 'comma', encode: true}),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const loadOnePublicProject = async (id,withView) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/public/projects/${id}?countViews=${withView}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification('ProjectCard loading error');
    console.error(error);
  }
};
export const loadPublicProjects = async (params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get('nest/api/public/projects', {
          params,
          paramsSerializer: (p) => qs.stringify(p, {arrayFormat: 'comma', encode:  true}),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const addProject = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post('/nest/api/projects', data)
        .then((res) => res.data.payload)
        .catch((error) => {
          console.log(error);
          errorPromiseCather(error);
        })
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const uploadProjectImage = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post('/nest/api/projects/media/upload', data, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((res) => res.data.payload)
        .catch((error) => {
          errorPromiseCather(error);
        })
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const deleteProject = async (id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .delete(`nest/api/projects/${id}`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const updateProject = async (data, id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .put(`/nest/api/projects/${id}`, data)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

/*Project Forum*/


export const loadProjectComments = async (projectId, params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`nest/api/projects/${projectId}/comments`, {
          params,
          paramsSerializer: (p) => qs.stringify(p, {arrayFormat: 'comma', encode:  true}),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
}
export const loadOneCommentToProject = async (projectId, commentId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`nest/api/projects/${projectId}/comments/${commentId}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const loadProjectCommentReplies = async (projectId, commentId, page, count) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(
          `/nest/api/projects/${projectId}/comments?sort-by=createTime&sort-order=DESC&parent-id=${commentId}&page=${page}&count=${count}`
        )
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const createProjectComment = async (projectId, data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/projects/${projectId}/comments`, data)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
}

export const editProjectComment = async (projectId, commentId, data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .put(`nest/api/projects/${projectId}/comments/${commentId}`, data)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const deleteProjectComment = async (projectId, commentId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .delete(`nest/api/projects/${projectId}/comments/${commentId}`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const voteForProjectComment = async (projectId, commentId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .patch(`/nest/api/projects/${projectId}/comments/${commentId}/vote/increase`)
        .then((res) => res.data)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const createTicketOrder = async (projectId) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/orders/projects/${projectId}/tickets/book`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
}
export const loadProjectsOrders = async (projectId, params) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`nest/api/orders/projects/${projectId}`, {
          params,
          paramsSerializer: (p) => qs.stringify(p, {arrayFormat: 'comma', encode:  true}),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
}
