import axios from 'axios';
import { errorNotification, toggleLoader, errorPromiseCather } from 'utils';
import apiAxios from 'axiosConfig';


export const loadOnePublicProfile = async (id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/experts/${id}/profiles?expertId=${id}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message || `The expert doesn't exist or has been deleted`);
    console.error(error);
  }
};



export const sendInvestorData = async (data) => {
  try {
    return await toggleLoader(() =>
      axios
        .post(`nest/api/public/contactus/investor`, data)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error.message);
  }
};
export const sendContactUsRequest = async (data) => {
  try {
    return await toggleLoader(() =>
      axios
        .post(`nest/api/public/contactus/contactus`, data)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error.message);
  }
}
export const  submitYourProjectRequest = async (data) => {
  try {
    return await toggleLoader(() =>
      axios
        .post(`nest/api/public/contactus/project`, data)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error.message);
  }
}

export const sendProjectData = async (data) => {
  try {
    return await toggleLoader(() =>
      axios
        .post(`nest/api/public/contactus/project`, data)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error.message);
  }
};
