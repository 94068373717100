import { errorPromiseCather, toggleLoader } from '../utils';
import apiAxios from '../axiosConfig';

export const postTermsOfServiceDate = async (id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/experts/${id}/terms-of-service`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    console.error(error);
  }
};

export const postCookieDate = async (id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/experts/${id}/cookies-policy`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    console.error(error);
  }
};
