import { LOCALSTORAGE_KEYS } from '../../../contants/common';

const cookieDefaultValues = {
  strictlyNecessaryCookies: true,
  analyticsCookie: true,
  functionalCookie: true,
  targetingCookie: true,
};

export const cookieOnlyStrictly = {
  strictlyNecessaryCookies: true,
  analyticsCookie: false,
  functionalCookie: false,
  targetingCookie: false,
};

export const setDefaultCookieValue = () => {
  localStorage.setItem(LOCALSTORAGE_KEYS.COOKIE_PREFERENCES, JSON.stringify(cookieDefaultValues));
};

export const setOnlyStrictlyCookies = () => {
  localStorage.setItem(LOCALSTORAGE_KEYS.COOKIE_PREFERENCES, JSON.stringify(cookieOnlyStrictly));
};

export const getCookiePreferencesValues = () => {
  const values = localStorage.getItem(LOCALSTORAGE_KEYS.COOKIE_PREFERENCES);

  if (values) {
    return JSON.parse(values);
  }

  return null;
};

export const setSelectedCookiePreferences = (values) => {
  localStorage.setItem(LOCALSTORAGE_KEYS.COOKIE_PREFERENCES, JSON.stringify(values));
};
