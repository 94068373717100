import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import { Router } from 'react-router-dom';

import 'semantic-ui-css/semantic.min.css';
import 'index.scss';
import { ConfigProvider, Cache } from 'react-avatar';
import { browserHistory } from 'stores';

const cache = new Cache({
  sourceTTL: 0,
  sourceSize: 0,
});

ReactDOM.render(
  <Router history={browserHistory}>
    <ConfigProvider cache={cache}>
      <App />
    </ConfigProvider>
  </Router>,
  document.getElementById('root')
);
