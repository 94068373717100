import {makeAutoObservable, runInAction} from 'mobx';

export default class UiStore {
  counterRequest = 0;

  showNotAuthorizedModal = false;

  windowSize = {};

  showSendMessageModal = false;

  colorPicker = { color: '#475462', type: 'text' };
  sideBarOpened =false;
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  // Computed
  get loading() {
    return this.counterRequest > 0;
  }

  get isMobile() {
    return this.windowSize?.width <= 1040;
  }
  get isPhone() {
    return this.windowSize?.width <= 767;
  }
  get isDesktop() {
    return this.windowSize?.width > 1040;
  }
  get isSmallPhone() {
    return this.windowSize?.width <= 375;
  }
  // Actions

  increaseCounterRequest = () => {
    this.counterRequest += 1;
  };

  decreaseCounterRequest = () => {
    this.counterRequest -= 1;
  };

  openNotAuthorizedModal = () => {
    this.showNotAuthorizedModal = true;
  };

  closeNotAuthorizedModal = () => {
    this.showNotAuthorizedModal = false;
  };

  notAuthorizedHandler = (cb) => {
    if (!this.rootStore.userStore.isAuth) {
      this.openNotAuthorizedModal();
    } else if (cb && typeof cb === 'function') {
      cb();
    }
  };
  setSideBarOpened = (value) => {
    runInAction(() => {
      this.sideBarOpened = value
    })
  }
  setWindowSize = (size) => {
    this.windowSize = size;
  };

  setShowSendMessageModal = (value) => {
    this.showSendMessageModal = value;
  };
  setColorPicker = (value) => {
    this.colorPicker = value;
  };
}
