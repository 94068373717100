import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import 'components/blocks/Header/Header.scss';
import {ROUTES} from 'utils';
import NavbarItem from 'components/blocks/Header/NavbarItem';
import HiddenMenuItem from 'components/blocks/Header/HiddenMenu/HiddenMenuItem';
import {useHistory} from 'react-router-dom';
import {useUIStore, useUserStore} from 'stores';
import HiddenMenuSubItem from 'components/blocks/Header/HiddenMenu/HiddenMenuSubItem';

const HiddenMenu = () => {
    const [activeTab, setActiveTab] = useState(null);
    const {logout, isAuth, user} = useUserStore();
    const history = useHistory();
    const {isPhone, sideBarOpened, setSideBarOpened} = useUIStore();
    const navItems = [
        {
            iconClass: 'icon-compass',
            routePath: ROUTES.MAIN_PAGE,
            title: 'Home',
        },
        {
            iconClass: 'icon-compass',
            routePath: ROUTES.NEST,
            title: 'Community',
        },
        {
            iconClass: 'icon-info',
            routePath: ROUTES.ABOUT_US,
            title: 'About Us',
        },
      /*  {
            iconClass: 'icon-profile',
            subMenu: [
                {
                    routePath: ROUTES.MENTEES,
                    title: 'Mentees',
                },
                {
                    routePath: ROUTES.MENTOR,
                    title: 'Mentor',
                },
            ],
            title: 'Mentorship',
        },
        {
            iconClass: 'icon-book',
            subMenu: [
                {
                    routePath: ROUTES.COMPANIES,
                    title: 'Companies',
                },
                {
                    routePath: ROUTES.STUDENTS,
                    title: 'Students',
                },
            ],
            title: 'Micro-internship',
        },*/
        {
            iconClass: 'icon-user',
            routePath: `${ROUTES.PROFILE}/${user.expertId}`,
            title: 'View profile',
            needAuth: true,
        },
        {
            iconClass: 'icon-folder',
            routePath: ROUTES.MY_PROJECTS,
            title: 'My Listings',
            needAuth: true,
        },
        {
            iconClass: 'icon-payment',
            routePath: ROUTES.PAYMENTS_HISTORY,
            title: 'Payments',
            needAuth: true,
        },
        {
            iconClass: 'icon-users',
            routePath: ROUTES.FIND_EXPERT,
            title: 'Our Network',
            needAuth: true,
        },
        {
            iconClass: 'icon-settings',
            routePath: ROUTES.SETTINGS,
            title: 'Settings',
            needAuth: true,
        },
    ];
    useEffect(() => {
        setActiveTab(history.location.pathname);
    }, [history.location.pathname]);
    useEffect(() => {
        if (!isPhone) {
            setSideBarOpened(false);
        }
    }, [isPhone]);
    const toggleMenu = () => {
        setSideBarOpened(!sideBarOpened);
    };
    const logoutHandler = async () => {
        await logout();
        const logoutMessage = "You're logged out from your account";
        toggleMenu();
        history.push({pathname: ROUTES.SIGN_IN, logoutMessage});
    };
    return (
        <>
            <div className="nav-hidden-menu-trigger" onClick={toggleMenu}>
                <span className={`${sideBarOpened ? 'icon-close' : 'icon-menu'}`}/>
            </div>
            <div className="nav-hidden-menu" style={{maxWidth: sideBarOpened ? '5000px' : '0px'}}>
                <div className="nav-hidden-menu-wp">
                    <div className="nav-hidden-menu-content">
                        {navItems.map(({title, iconClass, routePath, needAuth, subMenu}) =>
                            routePath ? (
                                <HiddenMenuItem
                                    title={title}
                                    onClick={toggleMenu}
                                    routePath={routePath}
                                    iconClass={iconClass}
                                    needAuth={needAuth}
                                    active={activeTab === routePath}
                                />
                            ) : (
                                <HiddenMenuSubItem
                                    title={title}
                                    subMenu={subMenu}
                                    iconClass={iconClass}
                                    needAuth={needAuth}
                                    active={activeTab === routePath}
                                />
                            )
                        )}
                    </div>
                    {isAuth && (
                        <div className="nav-hidden-menu-footer pointer" onClick={logoutHandler}>
                            <span className="icon-log-out"/>
                            <div>Log out</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
export default observer(HiddenMenu);
