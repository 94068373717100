/* eslint-disable max-len */
import { makeAutoObservable, runInAction } from 'mobx';
import * as Api from 'services';
import { toastNotification } from 'utils/toastify';
import { tagManagerWrapper } from '../utils/helpers';

export default class AppStore {
  activePublicProfile = {};

  isOnline = navigator.onLine;

  apiError = false;

  showBeta = true;

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  loadOnePublicProfile = async (id) => {
    const result = await Api.loadOnePublicProfile(id);

    if (result) {
      runInAction(() => {
        this.activePublicProfile = result;
      });
      return true;
    }
    return false;
  };

  sendInvestorData = async (data) => {
    const res = await Api.sendInvestorData(data);
    if (res && res.status >= 200 && res.status < 300) {
      tagManagerWrapper({
        dataLayer: {
          event: 'shareholder_submit_done',
        },
      });
      toastNotification('Your form was sent');
    }
  };

  sendContactUsRequest = async (data) => {
    const res = await Api.sendContactUsRequest(data);
    if (res && res.status >= 200 && res.status < 300) {
      toastNotification('Your form was sent');
    }
  };

  submitYourProjectRequest = async (data) => {
    const res = await Api.submitYourProjectRequest(data);
    if (res && res.status >= 200 && res.status < 300) {
      toastNotification('Your form was sent');
    }
  };

  setOffline = () => {
    this.isOnline = false;
  };

  setOnline = () => {
    this.isOnline = true;
    this.apiError = false;
  };

  setShowBeta = (value) => {
    this.showBeta = value;
  };
}
