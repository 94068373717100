import React from 'react';
import logo from 'assets/images/logo.svg';
import smallogo from 'assets/images/logo-green.svg';
import {Link} from 'react-router-dom';
import {ROUTES} from 'utils';
import {useUIStore} from "stores";
import {observer} from "mobx-react-lite";

const Logo = () => {
  const {setSideBarOpened} = useUIStore();
  return <Link className="logo-image" to={ROUTES.MAIN_PAGE} onClick={() => setSideBarOpened(false)}>
    <img src={logo} className="md-logo" alt="bionabu"/>
    <img src={smallogo} className="sm-logo" alt="bionabu"/>
  </Link>
};
export default observer(Logo);
