import {errorNotification, errorPromiseCather, toggleLoader} from "utils";
import apiAxios, {CancelToken} from "axiosConfig";
import qs from "qs";


let cancelChatLoading = () => {};
export const loadChat = async (page = 0, q = null, archived = false) => {
  try {
    cancelChatLoading();
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/chat/common`, {
          params : {page,archived,q},
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'comma', encode:  true }),
          cancelToken: new CancelToken(function executor(c) {
            cancelChatLoading = c;
          }),
        })
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const loadChatRead = async (senderId, receiverId, page = 0) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/chat/read?sender-id=${senderId}&receiver-id=${receiverId}&page=${page}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const sendMessageChat = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/chat`, data)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const sendFileChat = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post(`/nest/api/attachments/upload`, data, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        })
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const downloadFileChat = async (attachmentKey) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/attachments/download/${attachmentKey}`, {
          responseType: 'blob',
        })
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const loadGroupChatRead = async (groupId, page = 0) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .get(`/nest/api/chat/groups/${groupId}/read?page=${page}`)
        .then((res) => res.data.payload)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const createGroupChat = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post('/nest/api/chat/groups', data)
        .then((res) => res.data.payload)
        .catch((error) => {
          console.log(error);
          errorPromiseCather(error);
        })
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const editGroupChat = async (data, id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .put(`/nest/api/chat/groups/${id}`, data)
        .then((res) => res.data.payload)
        .catch((error) => {
          console.log(error);
          errorPromiseCather(error);
        })
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
}
export const uploadGroupChatImage = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .post('/nest/api/chat/groups/avatars/upload', data, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((res) => res.data.payload)
        .catch((error) => {
          errorPromiseCather(error);
        })
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const deleteGroupChat = async (id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .delete(`nest/api/chat/groups/${id}`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const leaveGroupChat = async (id) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .delete(`nest/api/chat/groups/${id}/leave`)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};

export const toggleChatPin = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .patch(`/nest/api/chat/common/pin/toggle`, data)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
export const toggleChatToArchive = async (data) => {
  try {
    return await toggleLoader(() =>
      apiAxios
        .patch(`/nest/api/chat/common/archive/toggle`, data)
        .then((res) => res)
        .catch((error) => errorPromiseCather(error))
    );
  } catch (error) {
    errorNotification(error.message);
    console.error(error);
  }
};
