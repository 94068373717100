import {makeAutoObservable, runInAction, toJS} from 'mobx';
import * as Api from 'services';

export const chatTabMap = {
  main: "MAIN",
  groupCreate: "GROUP_CREATE",
  groupEdit: "GROUP_EDIT",
  groupInfo: "GROUP_INFO",
  archive : "ARCHIVE"
};

export default class ChatStore {

  chatContacts = [];
  activeContact = null;


  chatRead = [];
  chatReadCurrentPage = 0;
  chatReadLastPage = false;
  chatReadNeedToScroll = false;
  chatLoading = false;


  possibleGroupChatMembersTotalPages = 0;
  possibleGroupChatMembersCurrentPage = 0;
  possibleGroupChatMembers = []

  activeTab = chatTabMap.main;
  filterLoading = true;
  filterValue = null
  membersFilterValue = null;

  archiveContacts = [];

  constructor(rootStore) {
    makeAutoObservable(this, {rootStore: false});
    this.rootStore = rootStore;
  }


  loadChat = async (page = 0, q = null) => {
    const result = await Api.loadChat(page, q, false);
    if (result.content) {
      runInAction(() => {
        page === 0
          ? (this.chatContacts = result.content)
          : (this.chatContacts = [...this.chatContacts, ...result.content]);
      });
    }
    return result;
  };
  loadArchive = async (page = 0, q = null) => {

    const result = await Api.loadChat(page, q, true);
    if (result.content) {
      runInAction(() => {
        page === 0
          ? (this.archiveContacts = result.content)
          : (this. archiveContacts = [...this.archiveContacts, ...result.content]);
      });
    }
    return result;
  };
  toggleChatToArchive = async (data) => {
    const result = await Api.toggleChatToArchive(data);
    if (result.status === 204) {
      if ( this.activeTab === chatTabMap.main) {
        await this.loadChat()
      }
      if ( this.activeTab === chatTabMap.archive) {
        await this.loadArchive()
      }
      runInAction(() => {
        if(this.activeContact?.personalMessage) {
          this.activeContact.personalMessage.archived = !this.activeContact.personalMessage.archived
          this.activeContact.personalMessage.pinned = false
        }

        if (this.activeContact?.groupMessage) {
          this.activeContact.groupMessage.archived = !this.activeContact.groupMessage.archived
          this.activeContact.groupMessage.pinned = false
        }
      })
    }
  }
  toggleChatPin = async (data) => {
    const result = await Api.toggleChatPin(data)
    if (result.status === 204) {
      if ( this.activeTab === chatTabMap.main) {
        await this.loadChat()
      }
      if ( this.activeTab === chatTabMap.archive) {
        await this.loadArchive()
      }
      runInAction(() => {
        if(this.activeContact?.personalMessage) {
          this.activeContact.personalMessage.pinned = !this.activeContact.personalMessage.pinned
        }

        if (this.activeContact?.groupMessage) {
          this.activeContact.groupMessage.pinned = !this.activeContact.groupMessage.pinned
        }
      })
    }
  }
  setActiveContact = async (contact) => {
    runInAction(() => {
      this.activeContact = contact;
    });
    if (this.activeContact?.type === "PERSONAL") {
      await this.loadChatRead(this.activeContact.personalMessage.companion.id, this.rootStore.userStore.user.expertId, 0, true);
    }
    if (this.activeContact?.type === "GROUP") {
      await this.loadGroupChatRead(this.activeContact.groupMessage.group.id, 0, true);
    }
    runInAction(() => {
      this.chatReadNeedToScroll =true;
    });
  };
  updateStatusActiveContact = (status) => {
    runInAction(() => {
      this.activeContact.personalMessage.status = status;
    });
  };

  updateChat = (lastMessage) => {
    runInAction(() => {
      if ((this.activeContact.personalMessage?.receiverId === lastMessage.receiverId && this.activeContact.personalMessage?.senderId === lastMessage.senderId) ||
        (this.activeContact.personalMessage?.receiverId === lastMessage.senderId && this.activeContact.personalMessage?.senderId === lastMessage.receiverId)
      ) {
        if (lastMessage.type === "FRIENDSHIP_REQUESTED" || lastMessage.type === "FRIENDSHIP_REJECTED") {
          this.setActiveContact({
            ...this.activeContact, personalMessage: {
              ...this.activeContact.personalMessage,
              type: lastMessage.type,
              initiatorId: lastMessage.senderId,
              status: lastMessage.type === "FRIENDSHIP_REQUESTED" ? "NEW" : "REJECTED"
            }
          })
        }
      }
    });
  };
  loadChatRead = async (senderId, receiverId, page = 0, loading = false) => {
    loading && this.toggleChatLoading();
    const result = await Api.loadChatRead(senderId, receiverId, page);
    if (result) {
      runInAction(() => {
        page === 0
          ? (this.chatRead = result.content)
          : (this.chatRead = [...result.content, ...this.chatRead]);
        this.chatReadLastPage = result.last;
        this.chatReadCurrentPage = result.number;
        if (result.content[result.content.length - 1]?.notificationId) {
          this.rootStore.notificationStore.readOneNotification(result.content[result.content.length - 1].notificationId);
          this.rootStore.notificationStore.loadMessageNotifications(0, true);
        }
      });
      if(this.activeTab ===chatTabMap.main) {
        await this.loadChat();
      }
      if(this.activeTab ===chatTabMap.archive) {
        await this.loadArchive();
      }
    }
    this.rootStore.notificationStore.loadArchivedNotificationsCount();
    loading && this.toggleChatLoading();
    return result;
  };
  toggleChatLoading = async () => {
    runInAction(() => {
      this.chatLoading = !this.chatLoading;
    });
  };

  sendMessageChat = async (text, receiverId, keys) => {
    let data = null;
    if (keys?.length) {
      data = {
        text,
        receiverId,
        attachments: keys.map((key) => ({key})),
      };
    } else {
      data = {
        text,
        receiverId,
      };
    }
    const result = await Api.sendMessageChat(data);
    return result;
  }
  sendMessageChatViaWS = async (text, receiverId, keys, type) => {
    let data = null;
    if (keys?.length) {
      data = {
        text,
        receiverId,
        attachments: keys.map((key) => ({key})),
      };
    } else {
      data = {
        text,
        receiverId,
      };
    }
    if (type === "GROUP") {
      data.groupId = receiverId;
      delete data.receiverId;
      this.rootStore.websocketStore.sendGroupMessage(data)
    } else {
      this.rootStore.websocketStore.sendPrivateMessage(data)
    }
  };

  onChatMessageReceived = async (data) => {

    if (this.activeContact?.personalMessage?.senderId === data.sender.id || this.activeContact?.personalMessage?.receiverId === data.sender.id) {
      const responseChatRead = await this.loadChatRead(
        this.activeContact.personalMessage.companion.id,
        this.rootStore.userStore.user.expertId
      );
      const lastMessage = responseChatRead.content[responseChatRead.content.length - 1];
      if (lastMessage) {
        this.updateChat(lastMessage);
      }

    }
    await this.loadChat();
    this.chatReadNeedToScroll = true;

  }
  onGroupChatMessageReceived = async (data) => {

    if (this.activeContact?.groupMessage?.group.id === data.groupId) {
      const responseGroupChatRead = await this.loadGroupChatRead(
        this.activeContact.groupMessage.group.id,
      );
    }
    await this.loadChat();
    this.chatReadNeedToScroll = true;
  }

  loadGroupChatRead = async (groupId, page = 0, loading = false) => {
    loading && this.toggleChatLoading();
    const result = await Api.loadGroupChatRead(groupId, page);
    if (result) {
      runInAction(() => {
        page === 0
          ? (this.chatRead = result.content)
          : (this.chatRead = [...result.content, ...this.chatRead]);
        this.chatReadLastPage = result.last;
        this.chatReadCurrentPage = result.number;
        if (result.content[result.content.length - 1]?.notificationId) {
          this.rootStore.notificationStore.readOneNotification(result.content[result.content.length - 1].notificationId);
          this.rootStore.notificationStore.loadMessageNotifications(0, true);
        }
      });
      if(this.activeTab ===chatTabMap.main) {
        await this.loadChat();
      }
      if(this.activeTab ===chatTabMap.archive) {
        await this.loadArchive();
      }
    }
    this.rootStore.notificationStore.loadArchivedNotificationsCount();
    loading && this.toggleChatLoading();
    return result;
  };
  createGroupChat = async (data) => {
    data = await this.prepareGroupChatForm(data);
    const result = await Api.createGroupChat(data);
    this.loadChat()
  };

  editGroupChat = async (data, id) => {
    data = await this.prepareGroupChatForm(data);
    await Api.editGroupChat(data, id);
    await this.loadChat()
    this.setActiveContact(this.chatContacts.find(item => item.groupMessage?.group.id === id));
  }
  deleteGroupChat = async (groupId) => {
    const res = await Api.deleteGroupChat(groupId);
    if (res) {
      runInAction(() => {
        this.chatContacts = [
          ...this.chatContacts?.filter((chat) => {
              return !(groupId === chat.groupMessage?.group.id)
            }
          ),
        ];
        this.activeContact = {}
      });
    }
  }
  leaveGroupChat = async (groupId) => {
    const res = await Api.leaveGroupChat(groupId);
    if (res) {
      runInAction(() => {
        this.chatContacts = [
          ...this.chatContacts?.filter((chat) => {
              return !(groupId === chat.groupMessage?.group.id)
            }
          ),
        ];
        this.activeContact = {}
      });
    }
  }

  loadPossibleGroupChatMembers = async (expertId, page, count, q) => {
    const result = await Api.loadFriends(expertId, page, count, q);
    if (result) {
      runInAction(() => {
        this.possibleGroupChatMembers = result.content;
        this.possibleGroupChatMembersTotalPages = result.totalPages;
        this.possibleGroupChatMembersCurrentPage = 0;
      });
    }
  };
  loadPossibleGroupChatMembersMore = async (expertId, count, q) => {
    if (this.possibleGroupChatMembersCurrentPage < this.possibleGroupChatMembersTotalPages - 1) {
      const result = await Api.loadFriends(expertId, this.possibleGroupChatMembersCurrentPage + 1, count, q);
      if (result) {
        runInAction(() => {
          this.possibleGroupChatMembers = [...this.possibleGroupChatMembers, ...result.content];
          this.possibleGroupChatMembersTotalPages = result.totalPages;
          this.possibleGroupChatMembersCurrentPage = this.possibleGroupChatMembersCurrentPage + 1
        });
      }
    }
  }

  prepareGroupChatForm = async (data) => {
    if (data.avatarKey?.img) {
      data.avatarKey = data.avatarKey.img;
    } else if (data.avatarKey?.file) {
      const formData = new FormData();
      formData.append('image', data.avatarKey.file);
      const res = await Api.uploadGroupChatImage(formData);
      if (res.resourceKey) {
        data.avatarKey = res.resourceKey;
      }
    }
    return data;
  }
  setActiveTab = (tab) => {
    runInAction(() => {
      this.activeTab = tab;
    })

  }
  setFilterValue = async (value) => {
    runInAction(() => {
      this.filterValue = value
    })
    this.setFilterLoading(true);
    if (this.activeTab === chatTabMap.main) {
      await this.loadChat(0, this.filterValue);
    }
    if(this.activeTab === chatTabMap.archive) {
      await this.loadArchive(0, this.filterValue);
    }

    this.setFilterLoading(false);
  }
  setFilterLoading = (value) => {
    runInAction(() => {
      this.filterLoading = value;
    })
  }
  setMembersFilterValue = (value) => {
    runInAction(() => {
        this.membersFilterValue = value;
      }
    )
  }
  setChatReadNeedToScroll = (value) => {
    this.chatReadNeedToScroll = value;
  }
}
