import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import './CookiePopup.scss';
import { Button } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { ROUTES } from '../../../utils';
import { useUserStore } from '../../../stores';
import { postCookieDate } from '../../../services';
import CookiePreferences from '../../common/CookiePreferences';
import { getCookiePreferencesValues, setDefaultCookieValue } from './cookie-helper';

const CookiePopup = () => {
  const [showCookieConfig, setShowCookieConfig] = useState(false); // todo: don't merge with true
  const { user, setCookieDate, isAuth } = useUserStore();
  const [showRoot, setShowRoot] = useState(true);
  const { pathname } = useLocation();

  const cookiePreferences = getCookiePreferencesValues();

  const submitHandler = async () => {
    setDefaultCookieValue();

    if (process.env.REACT_APP_GA_TRACKING_CODE) {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GA_TRACKING_CODE,
      };
      TagManager.initialize(tagManagerArgs);
    }

    if (isAuth && user.expertId && !user.cookiesPolicyAcceptTime) {
      await postCookieDate(user.expertId);
      setCookieDate();
    }
    setShowRoot(false);
  };

  const hasOnlyLocalCookie = cookiePreferences && !isAuth;
  const hasLocalCookieNoUser = cookiePreferences && isAuth && !user?.cookiesPolicyAcceptTime;

  const hasLocalCookie = cookiePreferences;
  const hasUserCookie = isAuth && user?.cookiesPolicyAcceptTime;

  useEffect(() => {
    if (pathname === ROUTES.COOKIE_POLICY) {
      setShowRoot(false);
    } else {
      setShowRoot(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (hasLocalCookieNoUser && user.expertId) {
      postCookieDate(user.expertId);
      setCookieDate();
      setShowRoot(false);
    }
  }, [hasLocalCookieNoUser, isAuth, setCookieDate, user]);

  useEffect(() => {
    if (hasLocalCookie && hasUserCookie) {
      setShowRoot(false);
    }
  }, [hasLocalCookie, hasUserCookie]);

  if (!showRoot || hasOnlyLocalCookie || hasLocalCookieNoUser) {
    return null;
  }

  return (
    <div className="cookie-popup">
      <p className="cookie-popup-text">
        Bionabu uses cookies for essential functionality and to ensure you get the best experience
        on our website. For details, see our{' '}
        <Link className="cookie-popup-link" to={ROUTES.COOKIE_POLICY}>
          Cookie Policy
        </Link>{' '}
        and adjust your{' '}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span className="cookie-popup-link" onClick={() => setShowCookieConfig(true)}>
          Preferences
        </span>
        .
      </p>
      <Button onClick={submitHandler} className="cookie-popup-submit">
        Accept All
      </Button>
      <CookiePreferences
        onCloseRoot={() => setShowRoot(false)}
        show={showCookieConfig}
        onClose={() => setShowCookieConfig(false)}
      />
    </div>
  );
};

export default observer(CookiePopup);
