import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ModalWrapper from 'components/common/ModalWrapper/ModalWrapper';
import { Button, Form, Modal } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { shareHolderSchema } from 'utils';
import { ShareHolderContext } from 'context';
import { REGISTRATION_FIELDS, SHAREHOLDER_FIELDS } from 'contants/common';
import InputField from 'components/UI/InputField/InputField';
import TextAreaField from 'components/UI/TextAreaField/TextAreaField';
import 'components/common/ShareHolder/ShareHolder.scss';
import { useAppStore } from 'stores';
import { tagManagerWrapper } from '../../../utils/helpers';

const salutation = [
  { label: 'Miss', value: 'MISS' },
  { label: 'Mr', value: 'MR' },
  { label: 'Mrs', value: 'MRS' },
];

const ShareHolder = ({ triggerBtn }) => {
  const [salutationValue, setSalutationValue] = useState('MISS');
  const { sendInvestorData } = useAppStore();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(shareHolderSchema),
  });

  const modalRef = useRef();

  useEffect(() => {
    if (salutationValue) {
      setValue(SHAREHOLDER_FIELDS.SALUTATION, salutationValue);
    }
    return clearErrors();
  }, []);

  const submitHandler = async (data) => {
    if (data) {
      setLoading(true);
      await sendInvestorData(data);
      reset();
      setValue(SHAREHOLDER_FIELDS.SALUTATION, salutationValue);
      if (modalRef.current) modalRef.current.click();
      setLoading(false);
    }
  };

  const onChangeHandler = (e, data) => {
    if (data.value) {
      setValue(SHAREHOLDER_FIELDS.SALUTATION, data.value);
      setSalutationValue(data.value);
    }
  };
  const handleSubmitClick = () => {
    tagManagerWrapper({
      dataLayer: {
        event: 'shareholder_submit_button',
      },
    });
  };

  return (
    <ModalWrapper
      ref={modalRef}
      Trigger={triggerBtn}
      size="mini"
      className="shareholder-wrapper"
      withoutAuth
      unregisterCb={clearErrors}
    >
      <Modal.Header>Submit an Enquiry</Modal.Header>
      <Modal.Content>
        <div className="">
          <ShareHolderContext.Provider value={{ register, handleSubmit, errors, setValue }}>
            <Form noValidate onSubmit={handleSubmit(submitHandler)} className="shareholder">
              <Form.Group>
                <Form.Field
                  name={SHAREHOLDER_FIELDS.SALUTATION}
                  label="Salutation"
                  required
                  value={salutationValue}
                  error={
                    errors &&
                    errors[SHAREHOLDER_FIELDS.SALUTATION] && {
                      content: errors[SHAREHOLDER_FIELDS.SALUTATION].message,
                    }
                  }
                  {...register(SHAREHOLDER_FIELDS.SALUTATION)}
                />
                <Form.Group>
                  {salutation.map((s) => (
                    <Form.Radio
                      className="shareholder-radio"
                      label={s.label}
                      value={s.value}
                      checked={salutationValue === s.value}
                      onChange={onChangeHandler}
                      key={s.value}
                    />
                  ))}
                </Form.Group>
              </Form.Group>
              <InputField
                required
                label="First Name"
                name={REGISTRATION_FIELDS.NAME}
                context={ShareHolderContext}
                placeholder="Type your name here"
              />
              <InputField
                required
                label="Last Name"
                name={REGISTRATION_FIELDS.LAST_NAME}
                context={ShareHolderContext}
                placeholder="Type your last name here"
              />
              <InputField
                label="Company (optional)"
                name={SHAREHOLDER_FIELDS.COMPANY}
                context={ShareHolderContext}
                placeholder="Type your company here"
              />
              <InputField
                required
                label="Email"
                name={REGISTRATION_FIELDS.EMAIL}
                context={ShareHolderContext}
                placeholder="Type your email here"
              />
              <InputField
                label="Phone number (including your country code e.g. +44, etc.)"
                name={SHAREHOLDER_FIELDS.PHONE_NUMBER}
                context={ShareHolderContext}
                placeholder="Type your phone number here"
              />
              <TextAreaField
                required
                name={SHAREHOLDER_FIELDS.COMMENTS}
                placeholder="Type comments here"
                label="Comments"
                context={ShareHolderContext}
              />
              <Button
                disabled={loading}
                loading={loading}
                type="submit"
                positive
                onClick={() => handleSubmitClick()}
              >
                Submit
              </Button>
            </Form>
          </ShareHolderContext.Provider>
        </div>
      </Modal.Content>
    </ModalWrapper>
  );
};

export default observer(ShareHolder);
