import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useNotificationStore, useUIStore} from 'stores';
import {NavLink} from "react-router-dom";

const Messages = ({iconClass, title, routePath}) => {
  const {setSideBarOpened} = useUIStore();
  const {
    messageNotificationCount,
    loadMessageNotifications,
  } = useNotificationStore();

  useEffect(() => {
    loadMessageNotifications(0, true);
  }, [loadMessageNotifications]);

  return (
    <NavLink  to={routePath} onClick={() => setSideBarOpened(false)}>
      <div className="messages-wrapper">
        <span className={iconClass}/>
        {messageNotificationCount > 0 && <div
          className="counter">{messageNotificationCount < 100 ? messageNotificationCount : 99}</div>}
      </div>
    </NavLink>
  );
};


export default observer(Messages);
