import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';

const CloseButton = ({ onClose }, ref) => {
  return <i ref={ref} onClick={onClose} className="icon close icon-close" />;
};

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default observer(CloseButton, { forwardRef: true });
