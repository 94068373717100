export const ROUTES = {
  FIND_EXPERT: '/find-expert',
  SIGN_IN: '/signin',
  MEMBERSHIP: '/membership',
  MESSAGES: '/messages',
  NEST: '/platform',
  SIGN_UP: '/signup',
  CHAT: '/chat',
  PROFILE: '/profile',
  PUBLIC_PROFILE: '/public-profile',
  RESET_PASSWORD: '/reset-password',
  CONFIRM_EMAIL: '/confirm',
  PRIVACY_POLICY: '/privacy-policy',
  COOKIE_POLICY: '/cookie-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  SETTINGS: '/settings',
  CONNECTIONS: '/connections',
  MAIN_PAGE: '/',
  HOW_IT_WORKS: '/how-it-works',
  FAQ: '/faq',
  ACTIVITY: '/event',
  PROJECT: '/activity',
  PROJECT_OWNER_VIEW: '/activity-owner-view',
  MY_PROJECTS: '/my-listings',
  EDIT_PROJECT: '/edit-activity',
  EDIT_ACTIVITY: '/edit-event',
  PAYMENTS_HISTORY: '/payments',
  ABOUT_US: '/about',
  MICRO_INTERNSHIP: '/micro-internship',
  MENTOR: '/mentor',
  MENTEES: '/mentees',
  COMPANIES: '/companies',
  STUDENTS: '/students',
  OUR_MENTORS: '/our-mentors',
};
